import React, { useState } from "react";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  styled,
  Grid,
  Collapse,
} from "@mui/material";
import { useGet } from "../../../helpers/axios/useApi";
import { useAuth } from "../../../middlewares/auth";
import ObjectList from "./addinationDetails";

const StyledSelect = styled(Select)(({ theme }) => ({
  "& .MuiSelect-select": {
    padding: "5px 8px",
    fontSize: "0.875rem",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    borderColor: theme.palette.grey[300],
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
  "& .MuiInputLabel-root": {
    fontSize: "0.75rem",
  },
}));

const stages = [
  "Create",
  "Follow Up Required",
  "Booking",
  "Session",
  "Payment",
  "Documentation",
  "Success",
  "Not Interested",
  "DNP - Did Not Pick up",
  "Invalid Number",
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function TaskTabs({ tasks = [] }) {
  const [value, setValue] = useState(0);
  const [selectedStage, setSelectedStage] = useState("");
  const [openTaskId, setOpenTaskId] = useState(null);
  const { user } = useAuth();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleStageChange = (event) => {
    setSelectedStage(event.target.value);
  };

  const handleDescriptionToggle = (taskId) => {
    // If the clicked task is already open, close it; otherwise, open the clicked task
    setOpenTaskId(openTaskId === taskId ? null : taskId);
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const filteredTasks = tasks.filter((task) => {
    const isCalling = task.taskType.startsWith("Calling");
    return (
      selectedStage === "" ||
      (selectedStage === "Create" &&
        (isCalling || task.taskType === "Create")) ||
      task.taskType === selectedStage
    );
  });

  const { data: employees } = useGet(
    "employee/all",
    {},
    {},
    { queryKey: "employees" }
  );

  const getEmployeeName = (id) => {
    let employee = employees?.data?.message[0]?.find(
      (employee) => employee._id === `${id}`
    );
    return employee
      ? `${employee.name}`
      : user.name
      ? user.name
      : user.companyName;
  };

  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: "10px",
        mt: "10px",
        backgroundColor: "white",
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        padding: "10px",
        height: "80vh",
      }}
    >
      <Tabs value={value} onChange={handleChange} aria-label="task tabs">
        <Tab label="Tasks" id="tab-0" aria-controls="tabpanel-0" />
        <Tab label="Notes" id="tab-1" aria-controls="tabpanel-1" />
        <Tab label="Contacts" id="tab-2" aria-controls="tabpanel-2" />
        <Tab label="Call Log" id="tab-3" aria-controls="tabpanel-3" />
        <Tab label="Stage" id="tab-4" aria-controls="tabpanel-4" />
        <Tab label="Audit" id="tab-5" aria-controls="tabpanel-5" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Grid
          sx={{
            height: "70vh",
            overflowY: "scroll",
          }}
        >
          <Grid width={"30%"}>
            <FormControl fullWidth >
              <StyledSelect
                value={selectedStage}
                onChange={handleStageChange}
                size="small"
                displayEmpty // Allows empty selection for "All"
              >
                <MenuItem value="">
                  <em>All</em> {/* Optional styling for the "All" option */}
                </MenuItem>
                {stages.map((stage) => (
                  <MenuItem key={stage} value={stage}>
                    {stage}
                  </MenuItem>
                ))}
              </StyledSelect>
            </FormControl>
          </Grid>
          <Grid>
            {filteredTasks.length > 0 ? (
              filteredTasks.map((task) => (
                <Grid
                  key={task.id} // Ensure each task has a unique key
                  sx={{
                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                    borderRadius: "10px",
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                    mb: 2,
                  }}
                >
                  <Grid
                    display={"flex"}
                    justifyContent={"space-between"}
                    sx={{ mb: 2 }}
                  >
                    <Typography
                      sx={{
                        fontSize: "0.875rem",
                        fontWeight: "bold",
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 1,
                        lineHeight: 1.5,
                        color: "grey",
                      }}
                    >
                      <span style={{ color: "black" }}>{task.taskType}</span>{" "}
                      was assigned to{" "}
                      <span style={{ color: "black" }}>
                        {getEmployeeName(task.assignee) || "Unassigned"}
                      </span>{" "}
                      by{" "}
                      <span style={{ color: "black" }}>
                        {getEmployeeName(task.assignedBy)}
                      </span>{" "}
                      On
                      <span style={{ color: "black" }}>
                        {formatDate(task.createdAt)}
                      </span>{" "}
                      .
                    </Typography>
                  </Grid>

                  {task.status === "completed" && (
                    <Grid item xs={12} mt={-2.5}>
                      <Grid
                        display={"flex"}
                        justifyContent={"flex-start"}
                        sx={{ mb: 2 }}
                        gap={8}
                      >
                        <Typography
                          sx={{
                            fontSize: "0.875rem",
                            fontWeight: "bold",
                            ml: 2,
                            color: "grey",
                          }}
                        >
                          This Task was completed by{" "}
                          <span style={{ color: "black" }}>
                            {getEmployeeName(task.completedBy)}{" "}
                          </span>
                          On{" "}
                          <span style={{ color: "black" }}>
                            {" "}
                            {formatDate(task.completedAt)}
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  {/* Collapsible Description */}
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyItems: "space-between",
                      justifyContent: "space-between",
                      mt: -3,
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "0.875rem",
                          fontWeight: "bold",
                          cursor: "pointer",
                          color: "#4287f5",
                          mt: 2,
                        }}
                        onClick={() => handleDescriptionToggle(task._id)}
                      >
                        {openTaskId === task._id ? (
                          <>Hide Details</>
                        ) : (
                          <>View Details</>
                        )}
                      </Typography>
                      <Collapse in={openTaskId === task._id}>
                        <Typography sx={{ fontSize: "0.875rem", mt: 1 }}>
                          {task.description}{" "}
                          {/* Ensure you use the correct property */}
                        </Typography>
                        <Grid width={"100%"}>
                          <ObjectList data={task?.customFields} />
                        </Grid>
                      </Collapse>
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "0.875rem",
                          mt: 1,
                          height: "1.5rem",
                          width: "1.5rem",
                          backgroundColor: "#1c2d45",
                          textAlign: "center",
                          color: "white",
                          borderRadius: "50%",
                        }}
                      >
                        {task.points}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              ))
            ) : (
              <Typography>
                No tasks available for the selected stage.
              </Typography>
            )}
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        Notes Content
      </TabPanel>
      <TabPanel value={value} index={2}>
        Contacts Content
      </TabPanel>
      <TabPanel value={value} index={3}>
        Call Log Content
      </TabPanel>
      <TabPanel value={value} index={4}>
        Stage Content
      </TabPanel>
      <TabPanel value={value} index={5}>
        Audit Content
      </TabPanel>
    </Box>
  );
}

export default TaskTabs;
