import React, { useEffect, useMemo, useState } from "react";
import {
  TextField,
  TextareaAutosize,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  Typography,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel as MUIFormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  IconButton,
  Snackbar,
  Grid,
} from "@mui/material";
import { usePost } from "../../../helpers/axios/useApi";
import { useAuth } from "../../../middlewares/auth";
import Loading from "../../../assets/Loading/Index";
import { toast, ToastContainer } from "react-toastify";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
const FormRenderer = ({ template, onChange , leadDetails,pasteLink }) => {
  const [userFields, setUserFields] = useState({});
  const [bookingType, setBookingType] = useState(""); // State to track bookingType
  const [memoizedLink, setLink] = useState('');
  const [open, setOpen] = useState(false); // State for modal visibility
  const [linkOpen, setLinkOpen] = useState(false);
const [copyLink, setCopyLink] = useState('false')
  const [meetingDetails, setMeetingDetails] = useState({
    title: "",
    description: "",
    startTime: "",
    endTime: "",
  });

  const handleChange = (name, value) => {
    const updatedFields = { ...userFields, [name]: value };
    setUserFields(updatedFields);
    onChange(updatedFields);
  };

  useEffect(() => {
    let data = localStorage.getItem("zoomLink");
    if (data) {
      data = JSON.parse(data); 
      setLink(data);
    }
  },[open])

  const handleBookingTypeChange = (value) => {
    setBookingType(value);
    handleChange("bookingType", value); // Update userFields for bookingType
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    // Reset meeting details when closing the modal
    setMeetingDetails({
      title: "",
      description: "",
      startTime: "",
      endTime: "",
    });

  };

  const handleCloseLinkOpen = () => {
    setLink('')
    setLinkOpen(false);
  };

  const handleMeetingDetailsChange = (name, value) => {
    setMeetingDetails({ ...meetingDetails, [name]: value });
  };
  const handleZoomMeeting = usePost('/meetings/create')
  const {user} = useAuth()
  const handleSubmit = async() => {
     const meetingData = {
         start_time_Date: meetingDetails.startTime,
         end_time_Date: meetingDetails.endTime,
         meetingName: meetingDetails.title,
         meetingDescription: meetingDetails.description,
         registrants: [
          {
              email: user?.email,
              first_name: user?.name || user?.companyName,
              last_name:  user?.lastName ||user?.companyName
          },
          {
              email: 'imperialmilestones@gmail.com',
              first_name: leadDetails?.leadName,
              last_name: leadDetails?.lastName || `we are from ${user?.companyName}`
          }
      ]
     }
     const data = await handleZoomMeeting.mutateAsync({meetingData})
     if(data.data !== null){
         toast.success(data?.data?.message) 
         const link = data?.data?.data
        localStorage.setItem('zoomLink', JSON.stringify(link))
         handleCloseModal()

         
     }
     else{
         toast.error(data.error.error)
     }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(link)
      .then(() => {
        toast.success('Link copied to clipboard');
        handleCloseLinkOpen();
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };

  return (
    <Box component="form" noValidate autoComplete="off">
      <ToastContainer/>
      {template?.map((field, index) => {
        const commonStyles = {
          borderRadius: "4px",
          margin: "8px 0",
          width: "100%",
          boxShadow: 3,
        };

        switch (field.type) {
          case "text":
            case "text":
      // Check if field name is 'zoomLink'
         if (field.name === "zoomLink") {
        return (
          <TextField
            key={index}
            label={field.label}
            type="text"
            variant="outlined"
            value={memoizedLink} // Set value to the link
            disabled // Disable the field
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true, // Always keep the label shrunk
            }}
            sx={{
              border: "1px solid #ccc",
              "&:hover": { borderColor: "#1976d2" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#ccc", },
                "&:hover fieldset": { borderColor: "#1976d2" },
                "&.Mui-focused fieldset": { borderColor: "#1976d2" },
              },
              "& .MuiInputBase-input": {
                backgroundColor: "white",
                borderRadius: "4px",
                height: "10px",
                border: "none",
                "&:hover": { borderColor: "#1976d2" },
              },
            }}
          />
        );
      }
      case "text":
      // Check if field name is 'zoomLink'
         if (field.name === "physicalAddress") {
        return (
          <TextField
            key={index}
            label={field.label}
            type="text"
            variant="outlined"
            value={leadDetails.address} // Set value to the link
            disabled // Disable the field
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true, // Always keep the label shrunk
            }}
            sx={{
              border: "1px solid #ccc",
              "&:hover": { borderColor: "#1976d2" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#ccc", },
                "&:hover fieldset": { borderColor: "#1976d2" },
                "&.Mui-focused fieldset": { borderColor: "#1976d2" },
              },
              "& .MuiInputBase-input": {
                backgroundColor: "white",
                borderRadius: "4px",
                height: "10px",
                border: "none",
                "&:hover": { borderColor: "#1976d2" },
              },
            }}
          />
        );
      }
      // Handle other 'text' fields normally
      return (
        <TextField
          key={index}
          label={field.label}
          type="text"
          variant="outlined"
          className={field.className}
          fullWidth
          margin="normal"
          onChange={(e) => handleChange(field.name, e.target.value)}
          sx={{
            ...commonStyles,
            border: "1px solid #ccc",
            "&:hover": { borderColor: "#1976d2" },
            "& .MuiOutlinedInput-root": {
              "& fieldset": { borderColor: "#ccc" },
              "&:hover fieldset": { borderColor: "#1976d2" },
              "&.Mui-focused fieldset": { borderColor: "#1976d2" },
            },
          }}
        />
      );

          case "number":
          case "autocomplete":
            return (
              <TextField
                key={index}
                label={field.label}
                type={field.type || "text"}
                variant="outlined"
                className={field.className}
                fullWidth
                
                margin="normal"
                onChange={(e) => handleChange(field.name, e.target.value)}
                sx={{
                  ...commonStyles,

                  border: "1px solid #ccc",
                  "&:hover": { borderColor: "#1976d2" },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: "#ccc" },
                    "&:hover fieldset": { borderColor: "#1976d2" },
                    "&.Mui-focused fieldset": { borderColor: "#1976d2" },
                  },
                }}
              />
            );
          case "textarea":
            return (
              <TextareaAutosize
                key={index}
                required={field.required}
                aria-label={field.label}
                placeholder={field.label}
                maxLength={field.maxlength}
                minRows={field.rows}
                className={field.className}
                style={{
                  ...commonStyles,
                  padding: "10px",
                  border: "1px solid #ccc",
                  boxShadow: "inset 0 1px 3px rgba(0,0,0,0.1)",
                }}
                onChange={(e) => handleChange(field.name, e.target.value)}
              />
            );
          case "checkbox":
            return (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    name={field.name}
                    onChange={(e) => handleChange(field.name, e.target.checked)}
                    sx={{
                      "&.Mui-checked": {
                        color: "#1976d2",
                      },
                    }}
                  />
                }
                label={field.label}
              />
            );
          case "radio-group":
            return (
              <>
                <FormControl key={index}>
                  <Typography>{field.label}</Typography>
                  <RadioGroup
                    name={field.name}
                    onChange={(e) => handleBookingTypeChange(e.target.value)} // Update bookingType here
                  >
                    {field.values.map((option) => (
                      <MUIFormControlLabel
                        key={option.value}
                        value={option.value}
                        control={
                          <Radio
                            sx={{
                              color: "#1976d2",
                              "&.Mui-checked": { color: "#1976d2" },
                            }}
                          />
                        }
                        label={option.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                {bookingType === "Zoom" && (
                  <Button
                    variant="contained"
                    sx={{
                      marginTop: 4,
                      borderRadius: "4px",
                      boxShadow: 2,
                      backgroundColor: "#1976d2",
                      "&:hover": { backgroundColor: "#1565c0" },
                    }}
                    onClick={handleOpenModal} // Open modal on click
                  >
                    Generate Link
                  </Button>
                )}
              </>
            );
          case "select":
            return (
              <FormControl key={index} fullWidth margin="normal">
                <InputLabel>{field.label}</InputLabel>
                <Select
                  defaultValue=""
                  onChange={(e) => handleChange(field.name, e.target.value)}
                  sx={{
                    ...commonStyles,
                    "& .MuiSelect-select": {
                      padding: "10px",
                    },
                    "&:hover": { borderColor: "#1976d2" },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#ccc",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#1976d2",
                    },
                  }}
                >
                  {field.values.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          case "file":
            return (
              <TextField
                key={index}
                label={field.label}
                type="file"
                className={field.className}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  multiple: field.multiple,
                }}
                onChange={(e) => handleChange(field.name, e.target.files)}
                sx={{
                  ...commonStyles,
                  "& .MuiInputBase-root": { borderRadius: "4px" },
                }}
              />
            );
          case "date":
            return (
              <TextField
                key={index}
                label={field.label}
                type="datetime-local"
                className={field.className}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange(field.name, e.target.value)}
                sx={{
                  ...commonStyles,
                  "& .MuiInputBase-root": { borderRadius: "4px" },
                }}
              />
            );
          case "button":
            return (
              <Button
                key={index}
                variant="contained"
                className={field.className}
                sx={{
                  marginTop: 2,
                  borderRadius: "4px",
                  boxShadow: 2,
                  backgroundColor: "#1976d2",
                  "&:hover": { backgroundColor: "#1565c0" },
                }}
              >
                {field.label}
              </Button>
            );
          case "header":
            return (
              <Typography
                key={index}
                variant={field.subtype || "h2"}
                className={field.className}
              >
                {field.label}
              </Typography>
            );
          case "paragraph":
            return (
              <Typography
                key={index}
                variant="body1"
                className={field.className}
              >
                {field.label}
              </Typography>
            );
          case "hidden":
            return (
              <input
                type="hidden"
                key={index}
                name={field.name}
                value={field.value}
              />
            );
          default:
            return null;
        }
      })}

      
         <Dialog open={open} onClose={handleCloseModal} PaperProps={{ sx: { minWidth: 300, borderRadius: 5 , boxShadow: 24 , padding: 2, mb: 25} }}>

         <DialogTitle>Schedule a Zoom Meeting</DialogTitle>
         <DialogContent>
           <TextField
             label="Meeting Title"
             fullWidth
             margin="normal"
             value={meetingDetails.title}
             onChange={(e) => handleMeetingDetailsChange("title", e.target.value)}
           />
           <TextField
             label="Meeting Description"
             fullWidth
             margin="normal"
             value={meetingDetails.description}
             onChange={(e) => handleMeetingDetailsChange("description", e.target.value)}
           />
           <TextField
             label="Start Time"
             type="datetime-local"
             fullWidth
             margin="normal"
             value={meetingDetails.startTime}
             InputLabelProps={{
               shrink: true, // Always keep the label shrunk
             }}
             onChange={(e) => handleMeetingDetailsChange("startTime", e.target.value)}
           />
           <TextField
             label="End Time"
             type="datetime-local"
             fullWidth
             margin="normal"
             value={meetingDetails.endTime}
             InputLabelProps={{
               shrink: true, // Always keep the label shrunk
             }}
             onChange={(e) => handleMeetingDetailsChange("endTime", e.target.value)}
           />
           <Typography>
               {leadDetails?.leadName} - {leadDetails?.email}
           </Typography>
         </DialogContent>
         <DialogActions>
           <Button onClick={handleCloseModal} color="primary">
             Cancel
           </Button>
           <Button onClick={handleSubmit} color="primary">
             Schedule Meeting
           </Button>
         </DialogActions>
       </Dialog>

      
      
    </Box>
  );
};

export default FormRenderer;
