import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Paper,
  Collapse,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

// Utility function to capitalize the first letter of each key
const capitalizeFirstLetter = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1);

const RenderObject = ({ data = {}, level = 0 }) => {
  if (Array.isArray(data)) {
    return (
      <List disablePadding>
        {data.map((item, index) => (
          <RenderListItem
            key={index}
            data={item}
            level={level}
            name={`Item ${index + 1}`}
          />
        ))}
      </List>
    );
  }

  if (typeof data !== 'object' || data === null) {
    return null; // Handle non-object cases
  }

  const keys = Object.keys(data);

  return (
    <List disablePadding>
      {keys.map((key) => (
        <RenderListItem
          key={key}
          data={data[key]}
          level={level}
          name={capitalizeFirstLetter(
            key.replace(/([A-Z])/g, ' $1').trim()
          )}
        />
      ))}
    </List>
  );
};

const RenderListItem = ({ data, level, name }) => {
  const [open, setOpen] = React.useState(false);
  const isObject = typeof data === 'object' && data !== null;
  const isArray = Array.isArray(data);

  const handleToggle = () => setOpen(!open);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <ListItem button onClick={isObject ? handleToggle : undefined}>
        <ListItemText
          primary={name}
          secondary={
            isObject ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="caption" color="textSecondary" style={{ marginRight: 8 }}>
                  Additional details
                </Typography>
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </div>
            ) : (
              <Typography variant="body2">{data}</Typography> // Displaying value without quotes
            )
          }
        />
      </ListItem>
      {(isObject || isArray) && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <RenderObject data={data} level={level + 1} />
        </Collapse>
      )}
    </div>
  );
};

const ObjectList = ({ data }) => {
  return (
    <Paper
      style={{
        padding: 0,
        width: '100%',
        backgroundColor: 'transparent',
        boxShadow: 'none',
      }}
    >
      <RenderObject data={data} />
    </Paper>
  );
};

export default ObjectList;
