import React from 'react';
import { Tooltip } from '@mui/material';
import { styled } from '@mui/system';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} PopperProps={{ className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: '#333',  // Custom background color
    color: '#fff',            // Custom text color
    fontSize: '0.75rem',      // Custom font size
    padding: '8px',           // Custom padding
    borderRadius: '4px',      // Custom border radius
    boxShadow: theme.shadows[1], // Optional shadow
  },
  [`& .MuiTooltip-arrow`]: {
    color: '#333', // Custom arrow color
  },
}));

export default CustomTooltip;
