import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Grid,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useGet, usePost } from "../../../helpers/axios/useApi";
import LeadChangeDialog from "../sub-components/updateStage";
import { useAuth } from "../../../middlewares/auth";
import GridViewLead from '../sub-components/gridView';
import { Link } from "react-router-dom";
import SyncIcon from '@mui/icons-material/Sync';
import axios from "axios";
import { server_url } from "../../../utils/server";
import { useDispatch, useSelector } from "react-redux";
const GridLead = ({ Updated }) => {
  const [initialData, setInitialData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentLead, setCurrentLead] = useState(null);
  const [draggingItem, setDraggingItem] = useState(null);
  const [destinationStatus, setDestinationStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { user } = useAuth();
  const [startDate, setStartDate] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedTeam, setSelectedTeam] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const containerRef = useRef(null);
  const [teams, setTeams] = useState([]);
  const [employeesData, setEmployees] = useState([]);
  const [departmentsData, setDepartmentsData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [filters, setFilters] = useState({});
  const [created, setCreated] = useState([]);
  const [leads, setLeads] = useState(null);
  const [setLoadineMore, setLoadingMore] = useState(false);
  const statuses = [
    "Create",
    "Follow",
    "Booking",
    "Session",
    "Payment",
    "Success",
    "DNP",
    "Not",
    "Invalid"
  ];
  const { canvanFilter } = useSelector(
    (state) => state.leads
  );
  const dispatch = useDispatch();
  const fetchLeads = async (status) => {
    try {
      const token = localStorage.getItem('authToken'); // Replace 'authToken' with the actual key used in localStorage
      const response = await axios.get(`${server_url}/leads/get`, {
        params: {
          page: page + 1,
          limit: rowsPerPage,
          stage: status,
          contain : true,
          ...filters,
        },
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
      });
      const fetchedLeads = response?.data?.data?.leadsData;
      console.log(response?.data?.data?.stateCounts)
      const count = response?.data?.data.stateCounts;
      setLeads(count);
      console.log(fetchedLeads)
      return fetchedLeads; // Return fetched leads
    } catch (error) {
      console.error("Error fetching leads:", error);
      setErrorMessage("Failed to fetch leads. Please try again.");
      return []; // Return empty array on error
    }
  };


  

  const fetchAllLeads = async () => {
    try {
      const leadPromises = statuses.map(status => fetchLeads(status));
      const allLeadsResults = await Promise.allSettled(leadPromises);
  
      const combinedLeads = allLeadsResults
        .filter(result => result.status === "fulfilled") // Only keep fulfilled promises
        .flatMap(result => result.value); // Combine the successful leads
       if(setLoadineMore && combinedLeads.length > 0 && page > 0){
           setInitialData([
               ...initialData,
               ...combinedLeads
           ]);
           setLoadineMore(false);
       }
      // Only update state if we have fetched leads
      if (combinedLeads.length > 0 && page <= 0) {
        setInitialData(combinedLeads);
      }
      
    } catch (error) {
      console.error("Error fetching all leads:", error);
    }
  };

  useEffect(() => {
    fetchAllLeads(); // Fetch leads when component mounts or when page or rowsPerPage changes
  }, [page, rowsPerPage]);


  
  
  // useEffect(() => {
  //     fetchLeads();
  // }, [page, rowsPerPage, filters, Updated]);


  const { data: employees, isLoading: employeesLoading } = useGet(
    "employee/all",
    {},
    {},
    { queryKey: "employees" }
  );

  const { data: departments, isLoading: departmentsLoading } = useGet(
    "department/all",
    {},
    {},
    { queryKey: "departments" }
  );

  const { data: teamsData, isLoading: teamsLoading } = useGet(
    `department/team-get/`,
    { departmentId: selectedDepartment },
    {
      enabled: Boolean(selectedDepartment),
    }
  );

  const { mutate: updateLead, isLoading: updateLeadLoading } = usePost("leads/update");

  useEffect(() => {
    if (departments?.data?.message[0]) {
      setDepartmentsData(departments.data.message[0]);
    }
  }, [departments]);

  useEffect(() => {
    if (employees?.data?.message[0] && selectedTeam) {
      const filteredEmployees = employees.data.message[0].filter((employee) =>
        employee.team.some((teamId) => teamId === selectedTeam)
      );
      setEmployees(filteredEmployees);
    }
  }, [employees, selectedTeam]);

  useEffect(() => {
    if (teamsData?.data?.message[0]) {
      setTeams(teamsData.data.message[0]);
    }
  }, [teamsData]);



  const handleDragEnd = (result) => {
    // Clear any previously set dragging item
    setDraggingItem(null);
    
    // If there's no destination, return early
    if (!result.destination) {
      return;
    }
  
    const { source, destination } = result;
    const newStatus = destination.droppableId;
  
    // Only process if the item is moved to a different status
    if (source.droppableId !== newStatus) {
      const draggingLead = initialData?.find(item => item.leadId === result.draggableId);
      setDraggingItem(result.draggableId);
      setDestinationStatus(newStatus);
      setCurrentLead(draggingLead);
      setDialogOpen(true);
    }
  };
  
 

  const getEmployeeName = (id) => {
    const employee = employees?.data?.message[0]?.find(
      (employee) => employee._id === id
    );
    if (employee) {
      return ` ${employee.name}`;
    }
  };

  const totalDocuments = useCallback(
    (stage) => {
      return initialData?.filter((item) => item.stage === stage).length;
    },
    [initialData]
  );

  const handleSuccess = () => {
    setInitialData((prevData) =>
      prevData.map((item) =>
        item.leadId === draggingItem
          ? { ...item, stage: destinationStatus }
          : item
      )
    );
    setDraggingItem(null);
    setDestinationStatus("");
    setCurrentLead(null);
    setStartDate("");
    setSelectedDepartment("");
    setSelectedTeam("");
    setSelectedEmployee("");
    setDialogOpen(false);
    Updated(true);
  };

  const handleError = (error) => {
    console.error("Error updating lead details:", error);
    setErrorMessage("Failed to update lead. Please try again.");
  };

  const groupedInitialData = (status) => {
      
      console.log(status,'dsfds')
      if (!status || !initialData) return [];
      const filteredData = initialData.filter((item) => item.stage === status);
      console.log(filteredData,'filteredData')
      return filteredData;
    }

  

    const loadMoreLeads = (e, status) => {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      if (scrollHeight - scrollTop <= clientHeight + 50) { // Adjust threshold as needed
        setPage((prevPage) => prevPage + 1);
        setLoadingMore(true);
      }
    };
  const handleDragStart = (start) => {
    clearInterval(window.scrollInterval);
  
    const container = containerRef.current;
  
    const scrollContainer = () => {
      if (!container) return;
  
      const scrollLeft = container.scrollLeft;
      const containerWidth = container.offsetWidth;
      const totalWidth = container.scrollWidth;
  
      if (start.source.index * 250 > scrollLeft + containerWidth - 100) {
        container.scrollLeft += 20; // Scroll right
      } else if (start.source.index * 250 < scrollLeft + 100) {
        container.scrollLeft -= 20; // Scroll left
      }
    };
  
    window.scrollInterval = setInterval(scrollContainer, 100);
  };

  const handleFilterApply = async() => {
    const savedFilters = localStorage.getItem('columnFilters');
    if (savedFilters) {
      const filtersValues =  savedFilters ? JSON.parse(savedFilters) : [];
     
      setInitialData([]);
      // Create an object with the desired structure
      const newFilters =  filtersValues.reduce((acc, filter) => {
        // Assuming filter.id corresponds to the property name (e.g., 'mobile', 'leadName')
        acc[filter.id] = filter.value; // Directly assign filter.value to filter.id
   
        return acc;
      }, {});
    
      // Update state with new filters
      setFilters((prevFilters) => ({
      ...prevFilters,
        ...newFilters,
      }));
      fetchAllLeads();
     console.log(filters,'filasgfdasgasfgdsafgdsafasdsddsdfasters')
      // Fetch leads and dispatch action
      dispatch({
        type: "APPLY_CANVAN_FILTER",
        payload: null
      })

     
    }
    if(canvanFilter === true){
      setFilters({});
      setInitialData([]);
      setLoadingMore(false);
      setPage(0);
      fetchAllLeads();
      dispatch({
        type: "APPLY_CANVAN_FILTER",
        payload: false
      })
    }
  }
  useEffect(() => {
      
     handleFilterApply();
    
  }, [canvanFilter]);
  

  return (
    <>
      <LeadChangeDialog
        open={dialogOpen}
        onClose={() => { setDialogOpen(false), localStorage.removeItem('zoomLink') }}
        currentLead={currentLead}
        employees={employees}
        departmentsData={departmentsData}
        teams={teams}
        employeesData={employeesData}
        onSuccess={handleSuccess}
        newStage={destinationStatus}
        destinationStatus={destinationStatus}
        onError={handleError}
        isLoading={employeesLoading || departmentsLoading || teamsLoading}
        lead={currentLead}
      />
      <Box width="100%">
        <Snackbar
          open={!!errorMessage}
          autoHideDuration={6000}
          onClose={() => setErrorMessage("")}
        >
          <Alert onClose={() => setErrorMessage("")} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
        <DragDropContext onDragEnd={handleDragEnd}  onDragStart={handleDragStart}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided) => (
              <Box
                {...provided.droppableProps}
                ref={(el) => {
                  provided.innerRef(el);
                  containerRef.current = el; // Set the ref for the container
                }}
                sx={{
                  width: "100%",
                  overflowX: "auto",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  padding: { xs: "8px", sm: "12px", md: "16px" },
                  mt: 0,
                  display: "flex",
                  gap: "0rem",
                  height: "80vh",
                }}
              >
                {[
                  "Create",
                  "Follow Up Required",
                  "Booking",
                  "Session",
                  "Payment",
                  "Success",
                  "DNP - Did Not Pick up",
                  "Not Interested",
                  "Invalid Number",
                ].map((status) => (
                  <Box
                    key={status}
                    ref={provided.innerRef}
                    sx={{
                      width: "250px",
                      flex: "0 0 auto",
                      backgroundColor: "#f0f3f6",
                      border: "3px solid white",
                      borderRadius: "8px",
                      padding: "10px",
                      mb: 2,
                      textAlign: "center",
                      color: "grey",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: status === "Create" ? "#3a2f6b" : status === "Follow Up Required" ? "#36669c" : status === "Booking" ? "#41a0ae" : status === "Session" ? "#3ec995" : status === "Not Interested" ? "#b3aea8" : status === "DNP - Did Not Pick up" ? "#82591b" : status === "Invalid Number" ? "#2e2d2b" : "#3fbf4a",
                        width: "100%",
                        height: "40px",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "white",
                      }}
                    >
                      <Grid display="flex" flexDirection="column" gap={0}>
                        <Typography variant="h6" color="white" fontSize={"16px"}>
                          {status}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          sx={{
                            color: "white",
                            fontSize: "12px",
                            fontWeight: "bold",
                            height: "20px",
                            textAlign: "center",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "max-content",
                            padding: "0px 5px",
                            borderRadius: "10px",
                            backgroundColor: "#4287f5",
                            boxShadow: "0 4px 6px rgba(0,0,0,0.5)",
                            position: "absolute",
                            top: "0px",
                            right: "20px",
                          }}
                        >
                          { leads && leads[status] || 0}
                        </Typography>
                      </Grid>
                    </Box>
                    <Droppable droppableId={status} type="TASK">
                      {(provided) => (
                         <Box
                         ref={(el) => {
                           provided.innerRef(el);
                           containerRef.current = el; // Set the ref for the container
                         }}
                         {...provided.droppableProps}
                         onScroll={(e) => loadMoreLeads(e, status)}
                         sx={{
                           height: "66vh", // Fixed height for vertical scrolling
                           marginTop: "10px",
                           position: "relative",
                           overflowY: "auto", // Enable vertical scrolling
                           overflowX: "hidden", // Disable horizontal scrolling
                           "&::-webkit-scrollbar": {
                             display: "none", // Optional: hide the scrollbar
                           },
                         }}
                       >
                          <Box>
                          {groupedInitialData(status).map((item, index) => (
                            <Draggable
                              key={item.leadId}
                              ref={provided.innerRef}
                              draggableId={item.leadId}
                              index={index}
                              isDragDisabled={status === "Success"}
                            >
                              {(provided) => (
                                <Box
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                mt={1}
                              >
                                  <Link to={`/leads/${item._id}`} style={{ textDecoration: 'none', color: 'black' }}>
                                    <GridViewLead
                                      leadId={item.leadId}
                                      leadName={item.leadName}
                                      city={item.city}
                                      email={item.email}
                                      mobile={item.mobile}
                                      assignee={getEmployeeName(item.assignee[0]) || "Unassigned"}
                                      owner={getEmployeeName(item.owner) || user.companyName}
                                      reportingManager={getEmployeeName(item.reportingManager) || "Not Assigned"}
                                      source={item.source}
                                      createdAt={item.created}
                                      type={item.type}
                                      stage={item.stage}
                                      newStage={item.newStage}
                                      newLead={item}
                                    />
                                  </Link>
                                </Box>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                          </Box>
                        </Box>
                      )}
                    </Droppable>
                  </Box>
                ))}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </>
  );
};

export default GridLead;
