import { Box, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import EmpDetails from '../../components/emplyoee/empDetails'
import { useParams } from 'react-router-dom'
import { useGet } from '../../helpers/axios/useApi'
import ProfileNav from '../../components/user/profiveNav'
import CalenderView from '../../components/emplyoee/calenderView'
import EmplyoeeDetailTab from '../../components/emplyoee/tabDetails'

function EmplyoeeDetails() {
    const {id} = useParams()
   

  const getTimes = (month, year) => {
    console.log(month, year)
  }
  return (
    <>
       <Box sx={{ width: "80%", display: "flex", flexDirection: "column", gap: "1rem" }}>
       <Box
        pt={3}
        sx={{
          width: "99%",
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            width: "100%",
            display: "flex",
            position: "sticky",
            top: 0,
            zIndex: 999,
          }}
        >
          <Grid item xs={12} md={12} container justifyContent="flex-end">
          
            <ProfileNav />
          </Grid>
        </Grid>
      </Box>
         <Grid width={"100%"} display={"flex"} gap={"1rem"}>
         <Grid width={"55%"} >
                 <EmpDetails />
            </Grid>
            <Grid width={"47%"} sx={{
                backgroundColor: "white",
                borderRadius: "10px",
                padding: "1rem"

            }}>
                 <Grid width={"100%"}>
                    <Grid height={"47%"}>
                    <CalenderView getTimes={getTimes} size={{
                        height:300,
                        width:'100%'
                    }}/>
                    </Grid>
                    <Grid maxWidth={"77vh"} mt={"1rem"}  width={"100%"}>
                        <EmplyoeeDetailTab/>
                    </Grid>
                   
                 </Grid>
                 
                </Grid>
         </Grid>
            
       </Box>
    
    </>
  )
}

export default EmplyoeeDetails
