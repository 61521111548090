import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';

// Create a styled TextField with custom styles
const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none', // Remove border
    },
    '&:hover fieldset': {
      border: 'none', // Remove border on hover
    },
    '&.Mui-focused fieldset': {
      border: 'none', // Remove border on focus
    },
    borderRadius: '25px', // Add border radius
  },
  '& .MuiInputBase-input': {
    backgroundColor: 'white', // White background
    borderRadius: '25px', // Add border radius
    height:'10px',
    border: 'none', // Remove border
  },
}));


  /**
   * A custom search input component that provides a simple search functionality.
   * @param {function} onChange - A callback function to be called when the search input value changes.
   * @returns {React.ReactElement} - A custom search input element with a search icon.
   */

function SearchInput({ onChange }) {
  const [value, setValue] = useState('');

  // Handle change event
  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <CustomTextField
      variant="outlined"
      placeholder="Search..."
      value={value}
      onChange={handleChange}
      fullWidth
      InputProps={{
        sx: {
            bgcolor: 'white',
        },
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon sx={{ color: 'black' }} />
          </InputAdornment>
        ),
      }}
    />
  );
}

export default SearchInput;
