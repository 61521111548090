import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomTooltip from '../customTooltip';
import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import LeadChangeDialog from '../sub-components/updateStage';
import { useAuth } from '../../../middlewares/auth';

function LeadDetailNav({ tasks = [], lead = {} }) {
    const [currentStage, setCurrentStage] = useState(lead?.stage || '');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [nextStage, setNextStage] = useState('');
    const {user} = useAuth()
    useEffect(() => {
        setCurrentStage(lead?.stage || '');
    }, [lead]);

    const handleSuccess = () => {
        // taskUpdated();
        setDialogOpen(false);
    };
    
    const handleError = () => console.error("Error occurred");
    const handleClose = () => {setDialogOpen(false), localStorage.removeItem('zoomLink')};

    const openDialog = (stage) => {
        setNextStage(stage); // Set the clicked stage as nextStage
        setDialogOpen(true);
    };

    const getStageStatus = (stage) => {
        if (stage === "Create") return true;

        const tasksForStage = tasks?.filter(task => task.taskType === stage);
        if (tasksForStage.length === 0) return false;

        return tasksForStage.every(task => task.status === 'completed');
    };

    const stages = [
        "Create", "Follow Up Required", "Booking", "Session", "Payment",
        , "Success", "Not Interested", "DNP - Did Not Pick up", "Invalid Number"
    ];

    const isDisabled = (stage) => {
        if (currentStage !== 'Booking' && stage === 'Session') return true;
        if (currentStage !== 'Session' && stage === 'Payment') return true;
        if (currentStage !== 'Payment' && stage === 'Success') return true;
        if (currentStage === 'Not Interested' || currentStage === 'Invalid Number') {
             if(user.role === 'Admin' || user.role === 'superAdmin' || user.role === 'Manager') return false;
             return true;
        }
        
    };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    width: "97%",
                    gap: "0.3rem",
                    justifyContent: "space-around",
                    borderRadius: "10px",
                    marginTop: "30px",
                    padding: "5px 0px",
                    backgroundColor: "white",
                    alignItems: "center",
                }}
            >
                <Grid container alignItems="center" spacing={1} width={"17%"} ml={-10}>
                    <Grid item>
                        <CustomTooltip title="Leads Overview">
                            <Diversity3OutlinedIcon
                                sx={{
                                    fontSize: "35px",
                                    backgroundColor: "#4287f5",
                                    padding: "10px",
                                    borderRadius: "10%",
                                    color: "white",
                                }}
                            />
                        </CustomTooltip>
                    </Grid>
                    <Grid item sx={{ maxWidth: '150px', overflow: 'hidden' }}>
                        <Typography
                            sx={{
                                maxWidth: '100%',
                                fontSize: '14px',
                                color: '#4287f5',
                                fontWeight: 'bold',
                                textTransform: 'capitalize',
                                ml: 1,
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                            }}
                        >
                            {lead.leadName}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid display={"flex"} gap={2} width={"75%"} overflow={"auto"} ml={-20}>
                    {stages.map((stage) => {
                        const status = getStageStatus(stage);
                        const isCurrentStage = currentStage === stage;
   
                        return (
                            <Grid item key={stage} width={'min-content'}>
                                <Button
                                    disabled={isDisabled(stage)}
                                    onClick={() => openDialog(stage)} // Pass stage to openDialog
                                    sx={{
                                        backgroundColor: status
                                            ? "#07a345"
                                            : (isCurrentStage ? "#3874eb" : "#dcdce0"),
                                        padding: "5px 15px",
                                        borderRadius: "10px",
                                        fontSize: "13px",
                                        width: "max-content",
                                        cursor: "pointer",
                                        display: "flex",
                                        alignItems: "center",
                                        color: status || isCurrentStage ? "white" : "black",
                                    }}
                                >
                                    {status && <TaskAltIcon sx={{ marginRight: "5px" }} />}
                                    {stage}
                                </Button>
                            </Grid>
                        );
                    })}
                </Grid>

               
            </Box>

            <LeadChangeDialog
                open={dialogOpen}
                onClose={handleClose}
                currentLead={lead}
                onSuccess={handleSuccess}
                onError={handleError}
                lead = {lead}
                destinationStatus={nextStage} // Use nextStage for destinationStatus
            />
        </>
    );
}

export default LeadDetailNav;
