import { useEffect, useState } from 'react';
import { Notifications } from "@mui/icons-material";
import { Box, Grid, IconButton, Badge, Typography, useTheme, useMediaQuery, Paper, IconButton as MuiIconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, Avatar } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { CSSTransition } from 'react-transition-group';
import './index.css'; // Import CSS file for transitions
import { useAuth } from '../../../middlewares/auth';
import WorkTrackerTabs from '../working/tracker';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
function ProfileNav() {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen size is small or down
  const {user} = useAuth();
  const [showNotifications, setShowNotifications] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  useEffect(() => {
    // Simulate fetching or initializing notifications count
    setNotificationCount(4); // Adjust as needed
  }, []);

  const handleNotificationClick = () => {
    setShowNotifications(true);
    setNotificationCount(0); // Correctly update state
  };

  const handleCloseNotification = () => {
    setShowNotifications(false);
  };

  return (
    <Box
      sx={{
        width: "40%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "1rem",
        padding: isSmDown ? "0.5rem" : "0rem", // Adjust padding for smaller screens
        position: "relative" // Necessary for absolute positioning of notification tab
      }}
    >
      <IconButton
        sx={{
          backgroundColor: "white",
          height: "45px",
          width: "45px",
          borderRadius: "13px",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={handleNotificationClick}
      >
        <Badge
          badgeContent={notificationCount}
          color="error"
          overlap="circular"
          sx={{
            ".MuiBadge-dot": {
              borderRadius: "50%",
              height: "12px",
              width: "12px",
            },
          }}
        >
          <Notifications sx={{ color: "black" }} />
        </Badge>
      </IconButton >
      <IconButton color="primary" onClick={handleOpen}   sx={{
          backgroundColor: "white",
          height: "45px",
          width: "45px",
          borderRadius: "13px",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <WorkHistoryIcon />
      </IconButton>
      <Grid container alignItems="center" spacing={1} bgcolor={'white'} borderRadius={'10px'} mt={'0.01rem'} width={'100%'} p={1}>
        <Grid item>
          <Avatar
            src={user?.avatar || user.name}
            alt="profile"
            style={{
              width: isSmDown ? "24px" : "28px",
              height: isSmDown ? "24px" : "28px",
              borderRadius: "50%",
            }}
          />
        </Grid>
        <Grid item>
          <Typography
            variant={isSmDown ? "caption" : "body2"}
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: isSmDown ? "0.75rem" : "1.1rem",

            }}
          >
             {user?.name  ? user?.name : user?.companyName}
            
          </Typography>
        </Grid>
      </Grid>

      {/* Notification Tab */}
      <CSSTransition
        in={showNotifications}
        timeout={500}
        classNames="notification-tab"
        unmountOnExit
      >
        <Paper
          elevation={3}
          sx={{
            position: "absolute",
            top: "100%",
            right: 0,
            width: "100%", // Adjust width as needed
            padding: "1rem",
            borderRadius: "20px",
            backgroundColor: "white",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">Notifications</Typography>
            <MuiIconButton onClick={handleCloseNotification} size="small">
              <CloseIcon />
            </MuiIconButton>
          </Box>
          {/* Example Notifications */}
          <Box mt={2}>
            <Typography variant="body2">You have {notificationCount} new notifications.</Typography>
            {/* Add more notification content here */}
          </Box>
        </Paper>
      </CSSTransition>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md" sx={{ "& .MuiDialog-paper": { width: "70%", height: "55%" , mb: '15rem', bordeRedius: '20px'} }
    
    }  PaperProps={{ sx: { borderRadius: "25px" } }}>
        
        <DialogContent>
          <WorkTrackerTabs/>
        </DialogContent>
     
      </Dialog>
    </Box>
  );
}

export default ProfileNav;
