import { Box, Divider, Grid, Typography, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CloudDownload, Cancel, Edit } from '@mui/icons-material';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import SchoolIcon from '@mui/icons-material/School';
import { useGet, usePost } from '../../helpers/axios/useApi';
import { useParams } from 'react-router-dom';

function BasicDetail({  }) {
    const [open, setOpen] = useState(false);
    const [empDetails, setEmpDetails] = useState({})
    const [formData, setFormData] = useState({ ...empDetails });
    const [newFiles, setNewFiles] = useState([]);
    const {id} = useParams();
    const {data: emp, isLoading: empLoading, refetch} = useGet(
        `employee/employee-details`,
        { empId: id },
    )
   

    useEffect(() => {
        if (emp?.data?.data) {
            setEmpDetails(emp.data.data)
        }
    }, [emp])
    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };
    const { mutateAsync: updateEmployee, isLoading: isSubmitting } = usePost("employee/update");
    const getDateDifference = (startDate) => {
        const start = new Date(startDate);
        const end = new Date();
        let years = end.getFullYear() - start.getFullYear();
        let months = end.getMonth() - start.getMonth();
        let days = end.getDate() - start.getDate();

        if (days < 0) {
            months -= 1;
            const lastMonth = new Date(end.getFullYear(), end.getMonth(), 0);
            days += lastMonth.getDate();
        }

        if (months < 0) {
            years -= 1;
            months += 12;
        }

        return { years, months };
    };

    const difference = getDateDifference(empDetails?.joiningDate);
    const [hovered, setHovered] = useState(false);
   //  const { mutateAsync: updateEmployee, isLoading: isSubmitting } = usePost("employee/update");
    const handleOpen = () => {
        setOpen(true);
        setFormData({ ...empDetails });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleFileChange = (e) => {
        setNewFiles([...newFiles, ...Array.from(e.target.files)]);
    };

    const handleSubmit = async () => {
        // API call to update employee details with existing and new files
        const updatedFiles = [...(empDetails?.files || []), ...newFiles.map(file => ({
            name: file.name,
            url: URL.createObjectURL(file), // You would replace this with the actual upload URL after uploading
            thumbnail: 'default-thumbnail.png', // Set a default or actual thumbnail
        }))];

        const updateData = {
            employeeId : empDetails?._id,
            dob: formatDate(formData.dob),
            address: {
                city: formData.city,
                state: formData.state,
                country: formData.country,
                street: formData.street,
                zip: formData.zip
            }
        };
        await updateEmployee({ updateData });
        console.log('Updated data:', updateData);
        refetch();
        // Call your API here to submit updatedData
        handleClose();
    };

    return (
        <>
            <Box sx={{
                width: '100%',
                borderRadius: '10px',
                padding: '20px',
                backgroundColor: 'white',
                height: '53vh',
                mt: '-10px',
                overflow: 'auto',
            }}>
                <Grid>
                   <Grid display={'flex'} justifyContent={'space-between'}>
                   <Typography sx={{ fontSize: '18px', fontWeight: 700, textAlign: 'left' }}>Basic Information</Typography>
                    <IconButton onClick={handleOpen}>
                        <Edit color='primary'/>
                    </IconButton>
                   </Grid>
                   
                    <Grid sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: '10px', marginTop: '10px' }}>
                        <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                            <Typography sx={{ fontSize: '16px', fontWeight: 600, color: 'black', textAlign: 'left' }}>Join Date</Typography>
                            <Typography sx={{ fontSize: '16px', fontWeight: 500, backgroundColor: '#181533', color: 'white', borderRadius: '5px', padding: '5px 10px' }}>{formatDate(empDetails?.joiningDate)}</Typography>
                        </Grid>
                        <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                            <Typography sx={{ fontSize: '16px', fontWeight: 600, color: 'black', textAlign: 'left' }}>Experience</Typography>
                            <Typography sx={{ fontSize: '16px', fontWeight: 500, backgroundColor: '#181533', color: 'white', borderRadius: '5px', padding: '5px 10px' }}>{`${difference.months} mm, ${difference.years} yy`}</Typography>
                        </Grid>
                        <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                            <Typography sx={{ fontSize: '16px', fontWeight: 600, color: 'black', textAlign: 'left' }}>Employee ID</Typography>
                            <Typography sx={{ fontSize: '16px', fontWeight: 500, backgroundColor: '#181533', color: 'white', borderRadius: '5px', padding: '5px 10px' }}>{empDetails?.empId}</Typography>
                        </Grid>
                    </Grid>
                  
                </Grid>
                <Divider sx={{ marginTop: '20px' }} />
                <Grid mt={2}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 700, textAlign: 'left' }}>Personal Information</Typography>
                    <Grid sx={{ display: 'flex', gap: '10px', marginTop: '10px', width: '100%' }}>
                        <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '30%' }}>
                            <Typography sx={{ fontSize: '16px', fontWeight: 600, color: 'black', textAlign: 'left' }}>DOB</Typography>
                            <Typography sx={{ fontSize: '16px', fontWeight: 500, backgroundColor: 'white', color: 'grey', borderRadius: '5px', padding: '5px 10px', boxShadow: '0 2px 4px rgba(0,0,0,0.5)' }}>{formatDate(empDetails?.dob) || 'NA'}</Typography>
                        </Grid>
                        <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '75%' }}>
                            <Typography sx={{ fontSize: '16px', fontWeight: 600, color: 'black', textAlign: 'left' }}>Address</Typography>
                            <Grid display={'flex'} gap={1} flexWrap={'wrap'}>
                                <Typography sx={{ fontSize: '16px', fontWeight: 500, backgroundColor: 'white', color: 'grey', borderRadius: '5px', padding: '5px 10px', boxShadow: '0 2px 4px rgba(0,0,0,0.5)' }}>{empDetails?.address?.street || 'NA'}</Typography>
                                <Typography sx={{ fontSize: '16px', fontWeight: 500, backgroundColor: 'white', color: 'grey', borderRadius: '5px', padding: '5px 10px', boxShadow: '0 2px 4px rgba(0,0,0,0.5)' }}>{empDetails?.address?.city || 'NA'}</Typography>
                                <Typography sx={{ fontSize: '16px', fontWeight: 500, backgroundColor: 'white', color: 'grey', borderRadius: '5px', padding: '5px 10px', boxShadow: '0 2px 4px rgba(0,0,0,0.5)' }}>{empDetails?.address?.state || 'NA'}</Typography>
                                <Typography sx={{ fontSize: '16px', fontWeight: 500, backgroundColor: 'white', color: 'grey', borderRadius: '5px', padding: '5px 10px', boxShadow: '0 2px 4px rgba(0,0,0,0.5)' }}>{empDetails?.address?.country || 'NA'}</Typography>
                                <Typography sx={{ fontSize: '16px', fontWeight: 500, backgroundColor: 'white', color: 'grey', borderRadius: '5px', padding: '5px 10px', boxShadow: '0 2px 4px rgba(0,0,0,0.5)' }}>{empDetails?.address?.zip || 'NA'}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider sx={{ marginTop: '20px' }} />
                <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}>
                            <Typography sx={{ fontSize: '16px', fontWeight: 600, color: 'black', textAlign: 'left' }}>Bank Details</Typography>
                            <Grid display={'flex'} gap={1} flexWrap={'wrap'}>
                                <Typography sx={{ fontSize: '16px', fontWeight: 500, backgroundColor: 'white', color: 'grey', borderRadius: '5px', padding: '5px 10px', boxShadow: '0 2px 4px rgba(0,0,0,0.5)' }}>{empDetails?.bankDetails?.bankName || 'NA'}</Typography>
                                <Typography sx={{ fontSize: '16px', fontWeight: 500, backgroundColor: 'white', color: 'grey', borderRadius: '5px', padding: '5px 10px', boxShadow: '0 2px 4px rgba(0,0,0,0.5)' }}>{empDetails?.bankDetails?.accountNumber || 'NA'}</Typography>
                                <Typography sx={{ fontSize: '16px', fontWeight: 500, backgroundColor: 'white', color: 'grey', borderRadius: '5px', padding: '5px 10px', boxShadow: '0 2px 4px rgba(0,0,0,0.5)' }}>{empDetails?.bankDetails?.ifscCode || 'NA'}</Typography>
                                <Typography sx={{ fontSize: '16px', fontWeight: 500, backgroundColor: 'white', color: 'grey', borderRadius: '5px', padding: '5px 10px', boxShadow: '0 2px 4px rgba(0,0,0,0.5)' }}>{empDetails?.bankDetails?.branch || 'NA'}</Typography>

                            </Grid>
                        </Grid>
                        <Divider sx={{ marginTop: '20px' }} />
                {/* File Section */}
                <Grid mt={2} display={'flex'} flexDirection={'column'} gap={2}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 700, textAlign: 'left' }}>Occupations Details</Typography>
                    <Grid display={'flex'} gap={3} justifyContent={'space-around'}>
                        <Grid>
                            <Typography sx={{ fontSize: '16px', fontWeight: 500, color: 'black', textAlign: 'left' }}>Documents</Typography>
                            <Grid
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    position: 'relative',
                                }}
                                onMouseEnter={() => setHovered(true)}
                                onMouseLeave={() => setHovered(false)}
                            >
                                <IconButton
                                    sx={{
                                        backgroundColor: 'transparent',
                                        transition: 'color 0.3s',
                                        color: hovered ? 'red' : '#0c64a8',
                                        fontSize: '50px',
                                    }}
                                >
                                    {hovered ? <Cancel sx={{ fontSize: '50px' }} /> : <FolderCopyIcon sx={{ fontSize: '50px' }} />}
                                </IconButton>

                                {/* Circular Avatars for each file */}
                                {hovered && empDetails?.files?.map((file, index) => (
                                    <IconButton
                                        key={index}
                                        component="a"
                                        href={file.url}
                                        download
                                        sx={{
                                            position: 'absolute',
                                            left: `${70 + index * 60}px`,
                                            width: '70px',
                                            height: '70px',
                                            borderRadius: '50%',
                                            overflow: 'hidden',
                                            transition: 'opacity 0.3s',
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                            },
                                        }}
                                    >
                                        <img
                                            src={file.thumbnail || 'default-thumbnail.png'}
                                            alt={`File ${index + 1}`}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                borderRadius: '50%',
                                                objectFit: 'cover',
                                            }}
                                        />
                                    </IconButton>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid>
                            <Typography sx={{ fontSize: '16px', fontWeight: 500, color: 'black', textAlign: 'left' }}>Qualification</Typography>
                            <Grid display={'flex'} gap={1} alignItems={'center'}>
                                <SchoolIcon sx={{ fontSize: '50px', color: '#0c64a8' }} />
                                <Typography sx={{ fontSize: '16px', fontWeight: 500, color: 'grey', textAlign: 'left' }}>{empDetails?.qualification || 'NA'}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            {/* Edit Modal */}
            <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { minWidth: 300, borderRadius: 5 , boxShadow: 24 , padding: 2} }}>
                <DialogTitle>Edit Employee Details</DialogTitle>
                <DialogContent>
                    
                    <TextField
                        margin="dense"
                        name="dob"
                        label="Date of Birth"
                        type="date"
                        fullWidth
                        variant="outlined"
                        value={formData.dob?.substring(0, 10) || ''}
                        onChange={handleChange}
                        InputLabelProps={{
                           shrink: true,
                         }}
                         sx={{ borderRadius: "5px", "& .MuiOutlinedInput-root": { "& fieldset": { borderColor: "white", borderRadius: "15px" } } }}
                    />
                    <TextField
                        margin="dense"
                        name="street"
                        label="Street"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formData?.address?.street || formData?.street}
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{ borderRadius: "5px", "& .MuiOutlinedInput-root": { "& fieldset": { borderColor: "white", borderRadius: "15px" } } }}
                    />
                    <TextField
                        margin="dense"
                        name="city"
                        label="City"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formData?.address?.city || formData?.city}
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{ borderRadius: "5px", "& .MuiOutlinedInput-root": { "& fieldset": { borderColor: "white", borderRadius: "15px" } } }}
                    />
                    <TextField
                        margin="dense"
                        name="state"
                        label="State"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formData?.address?.state || formData?.state}
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{ borderRadius: "5px", "& .MuiOutlinedInput-root": { "& fieldset": { borderColor: "white", borderRadius: "15px" } } }}
                    />
                    <TextField
                        margin="dense"
                        name="country"
                        label="Country"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formData?.address?.country || formData?.country}
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{ borderRadius: "5px", "& .MuiOutlinedInput-root": { "& fieldset": { borderColor: "white", borderRadius: "15px" } } }}
                    />
                    <TextField
                        margin="dense"
                        name="zip"
                        label="Zip Code"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formData?.address?.zip || formData?.zip}
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{ borderRadius: "5px", "& .MuiOutlinedInput-root": { "& fieldset": { borderColor: "white", borderRadius: "15px" } } }}
                    />
                    <TextField
                        margin="dense"
                        name="qualification"
                        label="Qualification"
                        type="text"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={formData.qualification}
                        onChange={handleChange}
                        sx={{ borderRadius: "5px", "& .MuiOutlinedInput-root": { "& fieldset": { borderColor: "white", borderRadius: "15px" } } }}
                    />
                    <input
                        type="file"
                        multiple
                        onChange={handleFileChange}
                        style={{ marginTop: '10px' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default BasicDetail;
