import React, { useEffect, useState } from "react";
import {
  Add,
  FilterAlt,
  GridView,
  ListAlt,
  ReplayOutlined,
  Settings,
  UploadFile,
  CalendarToday,
  Filter,
  Filter1,
  Map,
} from "@mui/icons-material";
import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PolylineIcon from '@mui/icons-material/Polyline';
import EventNoteIcon from "@mui/icons-material/EventNote";
import { Box, Grid, IconButton, Typography, TextField, Button, Drawer, FormControl, Checkbox, FormControlLabel, Modal } from "@mui/material";
import CustomTooltip from "../customTooltip";
import { DateRangePicker } from "react-date-range";
import { useDispatch, useSelector } from 'react-redux';
import { setFilters, toggleColumn, reorderColumns } from "../../../redux/actions/leads/filter";
import AddColumnFilterModal from "../sub-components/createWorkFlow";
import FileUploadButton from "../sub-components/uploadData";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import AddLeads from "../sub-components/addLeads";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import ErrorBoundary from "../../../helpers/axios/error";
import { useNavigate } from "react-router-dom";
import { useGet, usePost } from "../../../helpers/axios/useApi";
import * as XLSX from 'xlsx';
import { useAuth } from "../../../middlewares/auth";

import ColumnFilterPage from "../sub-components/filters";
/**
 * Function to reorder columns in the list view
 * @param {array} list - The list of columns
 * @param {number} startIndex - The index of the column to move
 * @param {number} endIndex - The index of the column to move to
 * @return {array} The reordered list of columns
 */
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

function LeadsNav() {
  const [active, setActive] = useState("list");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [leadsData, setLeadsData] = useState([]);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [dateRangeText, setDateRangeText] = useState("All dates");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const {user} = useAuth()
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.leads.filters || {});
  const columns = useSelector((state) => state.leads.column || []);
  const navigate  = useNavigate();
  const [filterModel, setFilterModel] = useState(false);
 const [workflowName,setWorkflowName] = useState('')
  const {toggle} = useSelector(state => state.leads)
  const handleSelect = (ranges) => {
    const { selection } = ranges;
    setSelectionRange(selection);
    setDateRangeText(
      `${selection.startDate.toDateString()} - ${selection.endDate.toDateString()}`
    );
  };

  const { downloadData } = useSelector(
    (state) => state.leads
  );

  
  const handleDownload = () => {
 
    // Ensure leads data is available
    if (!downloadData || downloadData.length === 0) {
      alert("No leads data available for download.");
      return;
    }

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert leads data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(downloadData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Leads");

    // Generate buffer
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Create a blob and trigger the download
    const file = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = fileURL;
    link.setAttribute('download', 'leads.xlsx'); // Specify the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };



  const handleClose = () => {
    setShowDatePicker(false);
    setDateRangeText("All dates");
    setSelectionRange({
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    });
   dispatch({
      type: 'APPLY_RANGE_FILTER',
      payload: null
    })
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseAddLead = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch({
      type: 'LEAD_TOGGLE',
      payload: false
  })
  }, []);
  const { mutateAsync: createForm, isLoading: createFormLoading } = usePost('/leads/form-create');
  const { data, isLoading , refetch} = useGet('/leads/form-get',{},{},{queryKey:'leadForm'});
  useEffect(() => {
    if (data) {
      setWorkflowName(data?.data?.message[0]?.formName || '');
    }
  }, [open, data]);
  const handleApply = () => {
    setDateRangeText(`${selectionRange.startDate.toLocaleDateString()} - ${selectionRange.endDate.toLocaleDateString()}`);
    setShowDatePicker(false);
    dispatch({
      type: 'APPLY_RANGE_FILTER',
      payload: selectionRange
    })
  };

  const handleSaveFilters = async () => {


    try {
      if (workflowName && columns.length > 0) {
        const formFieldData = {
          formName: workflowName,
          columns: columns
        }
        const result = await createForm(formFieldData);
        if (result.data) {
          toast.success('Workflow updated successfully!');
          localStorage.removeItem('workflowName');
          localStorage.removeItem('columns');
          refetch();
          handleClose();
          setDrawerOpen(false);
        } else {
          toast.error(result.error.error);
        }
      } else {
        throw new Error('Workflow name or columns data missing.');
      }
    } catch (error) {
      console.error('Failed to create workflow.');
    } 
  };

  const toggleButton = (buttonType) => {
    setActive(buttonType);
    if (buttonType === "list") {
      dispatch({
        type: 'LEAD_TOGGLE',
        payload: false
    })
    } else if (buttonType === "map"){
      dispatch({
        type: 'LEAD_TOGGLE',
        payload: null
    })
    } else {
      dispatch({
        type: 'LEAD_TOGGLE',
        payload: true
    })
    }
  };

  const handleFilterOpen = () => {
    setFilterModel(true);
  };

  const handleFilterClose = () => {
    setFilterModel(false);
  };
  
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    dispatch(setFilters({ ...filters, [name]: value }));
  };

  const handleApplyFilters = () => {
    setDrawerOpen(false);
  };
console.log(filters, columns, 'filters, columns')
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleToggleColumn = (columnId) => {
    const column = columns.find(col => col.id === columnId);
    if (column) {
      dispatch(toggleColumn(columnId, !column.visible));
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedColumns = reorder(
      columns,
      result.source.index,
      result.destination.index
    );
    dispatch(reorderColumns(reorderedColumns));
  };
  const handleRefetch = () => {
    dispatch({
      type: 'REFETCH_LEADS',
      payload: true
  })
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "97%",
          gap: "1rem",
          justifyContent: "space-around",
          borderRadius: "10px",
          marginTop: "30px",
          padding: "5px 30px",
          backgroundColor: "white",
          alignItems: "center",
        }}
      >
        <Grid container alignItems="center" spacing={2} width={"32%"}>
          <Grid item>
            <CustomTooltip title="Leads Overview">
              <Diversity3OutlinedIcon
                sx={{
                  fontSize: "40px",
                  backgroundColor: "#4287f5",
                  padding: "10px",
                  borderRadius: "10%",
                  color: "white",
                }}
              />
            </CustomTooltip>
          </Grid>
          <Grid item>
            <Typography variant="h6">Leads</Typography>
          </Grid>
          <Grid item>
            <CustomTooltip title="Create Fields">
              <IconButton
                onClick={()=>navigate('/leads/form/set-up-fields')}
                sx={{
                  display : user.role === "Admin" || user.role === "Manager" || user.role === "superAdmin" ? "block" : "none",
                  color: "#4287f5",
                  fontSize: "14px",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  border: "1px solid grey",
                  padding: "5px 15px",
                }}
              >
                <PolylineIcon fontSize="14px" /> Set Up Fields
              </IconButton>
            </CustomTooltip>
          </Grid>
          <Grid item>
            
              <FileUploadButton/>
             
          </Grid>
        </Grid>

        <Grid item>
          <CustomTooltip title="Date Range">
            <Box sx={{ position: 'relative' }}>
              <TextField
                sx={{
                  width: '200px',
                  '& .MuiInputBase-root': {
                    height: '40px',
                  },
                  '& .MuiOutlinedInput-root': {
                    paddingTop: '0px',
                  },
                }}
                value={dateRangeText}
                readOnly
                label="search by date"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => setShowDatePicker(!showDatePicker)}
                      sx={{
                        padding: '0',
                        marginLeft: '8px',
                        color: '#4287f5',
                      }}
                    >
                      <EventNoteIcon />
                    </IconButton>
                  ),
                }}
              />
              {showDatePicker && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: '110%',
                    width: '80%',
                    right: 210,
                    zIndex: '10',
                  }}
                >
                  <DateRangePicker
                    ranges={[selectionRange]}
                    months={2}
                    direction="horizontal"
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    retainEndDateOnFirstSelection={false}
                    onChange={handleSelect}
                  />
                  <Grid position={"absolute"} bottom={10} left={10} sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}>
                    <Button
                      sx={{
                        backgroundColor: "#4287f5",
                        color: "white",
                        padding: "2px 5px",
                        fontSize: "12px",
                      }}
                      onClick={handleApply}
                    >
                      Apply
                    </Button>
                    <Button
                      sx={{
                        backgroundColor: "#f52043",
                        color: "white",
                        padding: "2px 5px",
                        fontSize: "12px",
                      }}
                      onClick={handleClose}
                    >
                      Reset
                    </Button>
                  </Grid>
                </Box>
              )}
            </Box>
          </CustomTooltip>
        </Grid>

        <Grid
          sx={{
            display: "flex",
            gap: "1rem",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Grid item>
            <CustomTooltip title="Refresh">
              <IconButton
              onClick={handleRefetch}
                sx={{
                  color: "black",
                  fontSize: "16px",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  border: "1px solid grey",
                  padding: "5px 20px",
                }}
              >
                <ReplayOutlined fontSize="16px" />
              </IconButton>
            </CustomTooltip>
          </Grid>
          <Grid item>
            <CustomTooltip title="Add Lead">
              <IconButton
              onClick={handleClickOpen} 
                sx={{
                  color: "black",
                  fontSize: "20px",
                  bgcolor: "#4287f5",
                  padding: "5px",
                }}
              >
                <Add fontSize="16px" sx={{
                  color: "white",
                }} />
              </IconButton>
            </CustomTooltip>
          </Grid>
          <Grid item>
            <Grid
              sx={{
                border: "1px solid grey",
                borderRadius: "5px",
                overflow: "hidden",
                width: "100px",
              }}
            >
              <CustomTooltip title="List View">
                <IconButton
                  sx={{
                    color: active !== "list" ? "black" : "white",
                    fontSize: "16px",
                    backgroundColor: active !== "list" ? "white" : "#4287f5",
                    borderRadius: "0px",
                    padding: "5px",
                    width: "33%",
                  }}
                  onClick={() => toggleButton("list")}
                >
                  <ListAlt fontSize="16px" />
                </IconButton>
              </CustomTooltip>
              <CustomTooltip title="Grid View">
                <IconButton
                  sx={{
                    color: active !== "grid" ? "black" : "white",
                    fontSize: "16px",
                    backgroundColor: active !== "grid" ? "white" : "#4287f5",
                    borderRadius: "0px",
                    padding: "5px",
                    width: "33%",
                  }}
                  onClick={() => toggleButton("grid")}
                >
                  <GridView fontSize="16px" />
                </IconButton>
              </CustomTooltip>
              <CustomTooltip title="Grid View">
                <IconButton
                  sx={{
                    color: active !== "map" ? "black" : "white",
                    fontSize: "16px",
                    backgroundColor: active !== "map" ? "white" : "#4287f5",
                    borderRadius: "0px",
                    padding: "5px",
                    width: "34%",
                  }}
                  onClick={() => toggleButton("map")}
                >
                  <Map fontSize="16px" />
                </IconButton>
              </CustomTooltip>
            </Grid>
          </Grid>
          {/* <Grid item>
            <CustomTooltip title="CSV File">
              <Box
                sx={{
                  position: "relative",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "40px",
                  height: "40px",
                  borderRadius: "4px",
                  overflow: "hidden",
                  cursor: "pointer",
                }}
              >
                <InsertDriveFileIcon
                  sx={{
                    fontSize: "30px",
                    color: "#4287f5",
                  }}
                />
                <Typography
                  sx={{
                    position: "absolute",
                    top: "40%",
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "0.6rem",
                  }}
                >
                  Csv
                </Typography>
              </Box>
            </CustomTooltip>
          </Grid> */}
          <Grid item>
            <CustomTooltip title="Excel">
              <Box
                sx={{
                  position: "relative",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "30px",
                  height: "40px",
                  borderRadius: "4px",
                  overflow: "hidden",
                  cursor: "pointer",
                }}
              >
                <MenuBookIcon
                onClick={handleDownload}
                  sx={{
                    fontSize: "30px",
                    color: "#4287f5",
                  }}
                />
                <Typography
                  sx={{
                    position: "absolute",
                    top: "30%",
                    left: "15%",
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "0.9rem",
                  }}
                >
                  X
                </Typography>
              </Box>
            </CustomTooltip>
          </Grid>
          <Grid item>
            <CustomTooltip title="Settings">
              <IconButton
                sx={{
                  color: "black",
                  fontSize: "16px",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  border: "1px solid grey",
                  padding: "5px 20px",
                }}
                onClick={handleDrawerOpen}
              >
                <Settings fontSize="16px" />
              </IconButton>
            </CustomTooltip>
          </Grid>
          {toggle && <Grid item>
            <CustomTooltip title="Settings">
              <IconButton
                sx={{
                  color: "black",
                  fontSize: "16px",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  border: "1px solid grey",
                  padding: "5px 20px",
                }}
                onClick={handleFilterOpen}
              >
                <FilterAltIcon fontSize="16px" />
              </IconButton>
            </CustomTooltip>
          </Grid>}
          
        </Grid>

        {/* Filter Drawer */}
        <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose} sx={{
          '& .MuiDrawer-paper': {
            borderRadius: '16px',
            width: 250,
          },
        }}>
          <Box
            sx={{
              width: 250,
              padding: "16px",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Typography variant="h6">Filter Leads</Typography>
            <Button variant="contained" onClick={handleSaveFilters}>Save new filters</Button>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {columns.map((column, index) => (
                      <Draggable key={column.id} draggableId={column.id} index={index}>
                        {(provided) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{ mb: 1 }}
                          >
                            <FormControl fullWidth key={column.id} sx={{ mb: 0 }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={column?.visible}
                                    onChange={() => handleToggleColumn(column.id)}
                                  />
                                }
                                label={column.label}
                              />
                            </FormControl>
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        </Drawer>
      </Box>
      <Drawer open={filterModel}
        onClose={handleFilterClose}
        anchor="right"
        sx={{
          '& .MuiDrawer-paper': {
            borderRadius: '16px',
            width: 250,
          },
        }}>
          <ColumnFilterPage />
      </Drawer>
      <AddColumnFilterModal open={openModal} onClose={handleCloseModal} />
      <ErrorBoundary>
      <AddLeads open={open} onClose={handleCloseAddLead} />
      </ErrorBoundary>
      
    </>
  );
}

export default LeadsNav;
