import React, { useEffect, useState } from 'react'
import { useGet } from '../../../helpers/axios/useApi'
import { useParams } from 'react-router-dom'
import notfound from '../../../assets/images/not-found.jpg'
import EmployeeList from '../../listView/emplyoee'
import { Grid } from '@mui/material'
import Loading from '../../../assets/Loading/Index'
function TeamMember() {
   const  {id} = useParams()
   const [teamMembers, setTeamMembers] = useState([])
   const {data, isLoading} = useGet('employee/get-team-members', 
   {
    employeeId: id
   }
   )
    useEffect(() => {
      if(data?.data?.data){
        setTeamMembers(data?.data?.data)
      }
    }, [data])

   console.log(data, 'teammember')
  return (
   <>
   {isLoading && <Loading/>}
     {teamMembers?.length <1 && <>
       <img src={notfound} alt="not found" height={300} width={300} />
        </>}
      {teamMembers?.length > 0 && 
      <Grid sx={{width:'150vh', overflow: 'auto'}}>
          <EmployeeList employees={teamMembers}/>
      </Grid>
      }
   </>
  )
}

export default TeamMember
