import React, { useState } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import MapComponent from './map';
import ColumnFilterPage from '../../sub-components/filters';
import { useGet } from '../../../../helpers/axios/useApi';

const MapLeadView = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [filters, setFilters] = useState({});
  const [areaRadius, setAreaRadius] = useState(10000);
  const locations = [
    { name: 'Location 1', latitude: 23.1151736, longitude: 72.6318347 },
    { name: 'Location 2', latitude: 23.0115919, longitude: 72.5052771 },
    { name: 'Location 3', latitude: 23.0277378, longitude: 72.5588626 },
  ];
  const {
    data: leads,
    error,
    isLoading,
    refetch: refetchLeads,
  } = useGet(
    "/leads/get-map-lead",
    {
      ...filters,
      areaRadius
    },
    {},
    {
      queryKey: [
        "leads",
        {
          ...filters,
          areaRadius
        },
      ],
    }
  );

  const toggleEditing = () => {
    setIsEditing((prev) => !prev);
  };

  return (
    <Box sx={{ display: 'flex', width: '100%', gap: '0.1rem', margin: '0.3rem', height: '75vh', position: 'relative' }}>
      <Grid width={isEditing ? '30%' : '0'} sx={{ transition: 'width 0.3s' }}>
        <IconButton disabled onClick={toggleEditing} sx={{ marginBottom: 2 , zIndex: 999, position: 'absolute', top: 10, right: 20, backgroundColor: 'white', borderRadius: '50%', '&:hover': { backgroundColor: 'white' }}}>
          <FilterAltIcon sx={{ color: '#2f519c' }} />
        </IconButton>
        {isEditing && <ColumnFilterPage onApply={() => setFilters(data)} />}
      </Grid>
      <Grid
        width={isEditing ? '69%' : '99%'}
        sx={{
          height: '100%',
          backgroundColor: 'white',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          transition: 'width 0.3s',
        }}
      >
        <MapComponent locations={leads?.data?.data} />
      </Grid>
    </Box>
  );
};

export default MapLeadView;
