import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LeadDetailNav from '../../components/leads/leadDetails/nav';
import { useGet } from '../../helpers/axios/useApi';
import LeadDeatils from '../../components/leads/leadsNav/detailsNav';
import LeadDeatilsView from '../../components/leads/leadDetails/detail';
import TaskTabs from '../../components/leads/leadDetails/taskDetails';
import { SearchInput } from '../../components/search';
import ProfileNav from '../../components/user/profiveNav';
import AddColumnModal from '../../components/leads/sub-components/createWorkFlow';

function LeadDetails() {
    const [task, setTasks] = useState([]);
    const [leadDetail, setLead] = useState([]);
    const [points, setPoints] = useState([]);
    const [count , setCount] = useState(0)
    const { id } = useParams();
    const { data: tasks, isLoading: taskLoading , refetch} = useGet(
        `leads/task-get`,
        { leadId: id },
      );
      const { data: lead, isLoading: leadLoading } = useGet(
        `leads/details`,
        { leadId: id },
      );
    useEffect(() => {
        if (tasks?.data?.data) {
          console.log(tasks?.data, 'taskkk')
          setTasks(tasks.data?.data?.existingTasks);
          setPoints(tasks.data?.data?.totalPoints);
          setCount(tasks.data?.data?.existingTasks?.length)
        }
        if (lead?.data?.data) {
          setLead(lead.data.data);
        }
    },[tasks, lead])
    const handleUpdatedTask = () => {
         refetch()
         setTasks(tasks?.data?.data)
    }
    console.log(task,leadDetail)

 
    return (
        <>
            <Box width={'84%'} display={'flex'} flexDirection={'column'} gap={'1rem'}>
            <Box
        pt={3}
        sx={{
          width: "99%",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            width: "100%",
            display: "flex",
            position: "sticky",
            top: 0,
            zIndex: 999,
          }}
        >
          <Grid item xs={12} md={12} container justifyContent="flex-end">
          
            <ProfileNav />
          </Grid>
        </Grid>
      </Box>
                <Grid>
                <LeadDetailNav tasks={task} lead = {leadDetail} />
                </Grid>
                <Grid width={'100%'} display={'flex'} gap={'1rem'}>
                <Grid width={'30%'}>
                    <LeadDeatilsView lead={leadDetail} points={points} count = {count}/>
                </Grid>
                <Grid width={'65%'}>
                    <TaskTabs tasks={task} />
                </Grid>
                </Grid>
              
                 
            </Box>
            
        </>
    );
}

export default LeadDetails;
