import { Avatar, Box, Grid, Typography, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import EditIcon from '@mui/icons-material/Edit';
import UploadIcon from '@mui/icons-material/Upload';
import BasicDetail from './basicDetail';
import { useGet, usePost } from '../../helpers/axios/useApi';

function EmpDetails({ }) {
    const [empDetails, setEmpDetails] = useState({})
    const [avatar, setAvatar] = useState(empDetails?.avatar);
    const [isUploaded, setIsUploaded] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [openModal, setOpenModal] = useState(false); 
    const [formData, setFormData] = useState({ ...empDetails }); 
    const { mutateAsync: updateEmployee, isLoading: isSubmitting } = usePost("employee/update");
   const {id} = useParams();
    const {data: emp, isLoading: empLoading, refetch} = useGet(
        `employee/employee-details`,
        { empId: id },
    )
   

    useEffect(() => {
        if (emp?.data?.data) {
            setEmpDetails(emp.data.data)
        }
    }, [emp])
    const handleAvatarChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setAvatar(reader.result);
                setIsUploaded(true);
                setIsEdit(true);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleEditClick = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleChange = async(e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        
       
    };

    const handleSubmit = async () => {
        await uploadImage(empDetails?._id);
        handleCloseModal();
    };

    const uploadImage = async (id) => {
        console.log("Uploading image and updating details for ID:", id, formData);
        const updateData = {
            employeeId : empDetails?._id,
            ...formData
        }
        console.log(updateData, 'updateData');
        await updateEmployee({ updateData });
        refetch();
    };

    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "1rem" }}>
            <Grid sx={{ display: "flex", flexDirection: "row", gap: "1.5rem", backgroundColor: "white", padding: "1rem", borderRadius: "15px" }}>
                <Grid>
                    <Avatar
                        sx={{ width: 150, height: 150, fontSize: "4rem", color: "white", backgroundColor: "grey" }}
                        src={avatar}
                    />
                    {isEdit ? (
                        <IconButton component="label" onClick={() => uploadImage(empDetails?._id)} sx={{ 
                            backgroundColor: isUploaded ? "#d1e7dd" : "#94c793",
                            borderRadius: "5px",
                            padding: "5px 10px",
                            "&:hover": {
                                backgroundColor: isUploaded ? "#c1e3d4" : "#81d180",
                            },
                            marginTop: "1rem",
                        }}>
                            <UploadIcon sx={{ color: "black" }} />
                        </IconButton>
                    ) : (
                        <IconButton component="label" sx={{ 
                            backgroundColor: isUploaded ? "#d1e7dd" : "#94c793",
                            borderRadius: "5px",
                            padding: "5px 10px",
                            "&:hover": {
                                backgroundColor: isUploaded ? "#c1e3d4" : "#81d180",
                            },
                            marginTop: "1rem",
                        }}>
                            <input type="file" hidden accept="image/*" onChange={handleAvatarChange} />
                            <EditIcon sx={{ color: "black" }} />
                        </IconButton>
                    )}
                </Grid>
                <Grid sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                    <Grid sx={{ display: "flex", gap: "1rem" }}>
                        <Typography sx={{ fontSize: "1.5rem", fontWeight: "bold" }}>{empDetails?.name}</Typography>
                        <Typography sx={{
                            color: "white",
                            fontSize: "1rem",
                            borderRadius: "5px",
                            textAlign: "center",
                            padding: "5px 10px",
                            backgroundColor: empDetails?.status === "active" ? "green" : "#db2e34"
                        }}>{empDetails?.status}</Typography>
                        <IconButton onClick={handleEditClick}>
                            <EditIcon color='primary' />
                        </IconButton>
                    </Grid>
                    {/* Additional Employee Details */}
                    <Grid sx={{ display: "flex", gap: "1rem" }}>
                        <Typography sx={{ fontSize: "1rem", color: "#5c5e5d" }}>Role : <span style={{ color: 'black' }}>{empDetails?.role}</span></Typography>
                        <Typography sx={{ fontSize: "1rem", color: "#5c5e5d" }}>Position : <span style={{ color: 'black' }}>{empDetails?.position}</span></Typography>
                    </Grid>
                    <Grid sx={{ display: "flex", gap: "1rem" }}>
                        <Typography sx={{ fontSize: "1rem", color: "#5c5e5d" }}>Email : <span style={{ color: 'black' }}>{empDetails?.email}</span></Typography>
                    </Grid>
                    <Grid sx={{ display: "flex", gap: "1rem" }}>
                        <Typography sx={{ fontSize: "1rem", color: "#5c5e5d" }}>Phone : <span style={{ color: 'black' }}>{empDetails?.phone || "NA"}</span></Typography>
                    </Grid>
                    <Grid sx={{ display: "flex", gap: "1rem" }}>
                        <Typography sx={{ fontSize: "1rem", color: "#5c5e5d" }}>Company : <span style={{ color: 'black' }}>{empDetails?.companyName || "NA"}</span></Typography>
                    </Grid>
                    <Grid sx={{ display: "flex", gap: "1rem", alignContent: "center", alignItems: "center" }}>
                        <Link to={`${empDetails?.socialMedia?.twitter}`} target="_blank">
                            <XIcon sx={{ fontSize: "1rem", color: "black", cursor: "pointer" }} />
                        </Link>
                        <Link to={`${empDetails?.socialMedia?.linkedin}`} target="_blank">
                            <LinkedInIcon sx={{ fontSize: "1.3rem", color: "#0c72f0", cursor: "pointer" }} />
                        </Link>
                    </Grid>
                </Grid>
            </Grid>

            {/* Modal for Editing Employee Details */}
            <Dialog open={openModal} onClose={handleCloseModal} PaperProps={{ sx: { minWidth: 300, borderRadius: 5 , boxShadow: 24 , padding: 2} }}>
                <DialogTitle>Edit Employee Details</DialogTitle>
                <DialogContent>
                    <Grid sx={{ display: "flex", flexDirection: "column", gap: "1rem", width: "500px" , height: "300px" }}>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        sx={{ borderRadius: "5px", "& .MuiOutlinedInput-root": { "& fieldset": { borderColor: "white", borderRadius: "15px" } } }}
                    />
                    <TextField
                        margin="dense"
                        label="Role"
                        type="text"
                        fullWidth
                        variant="outlined"
                        name="role"
                        value={formData.role}
                        onChange={handleChange}
                        sx={{ borderRadius: "5px", "& .MuiOutlinedInput-root": { "& fieldset": { borderColor: "white", borderRadius: "15px" } } }}
                    />
                    <TextField
                        margin="dense"
                        label="Position"
                        type="text"
                        fullWidth
                        variant="outlined"
                        name="position"
                        value={formData.position}
                        onChange={handleChange}
                        sx={{ borderRadius: "5px", "& .MuiOutlinedInput-root": { "& fieldset": { borderColor: "white", borderRadius: "15px" } } }}
                    />
                    <TextField
                        margin="dense"
                        label="Email"
                        type="email"
                        fullWidth
                        variant="outlined"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        sx={{ borderRadius: "5px", "& .MuiOutlinedInput-root": { "& fieldset": { borderColor: "white", borderRadius: "15px" } } }}
                    />
                    <TextField
                        margin="dense"
                        label="Phone"
                        type="text"
                        fullWidth
                        variant="outlined"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        sx={{ borderRadius: "5px", "& .MuiOutlinedInput-root": { "& fieldset": { borderColor: "white", borderRadius: "15px" } } }}
                    />
                    <TextField
                        margin="dense"
                        label="Company Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        name="companyName"
                        value={formData.companyName}
                        onChange={handleChange}
                        sx={{ borderRadius: "5px", "& .MuiOutlinedInput-root": { "& fieldset": { borderColor: "white", borderRadius: "15px" } } }}
                    />
                    {/* Social Media Links */}
                    <TextField
                        margin="dense"
                        label="Twitter"
                        type="text"
                        fullWidth
                        variant="outlined"
                        name="twitter"
                        value={formData.socialMedia?.twitter || ''}
                        onChange={handleChange}
                        sx={{ borderRadius: "5px", "& .MuiOutlinedInput-root": { "& fieldset": { borderColor: "white", borderRadius: "15px" } } }}
                    />
                    <TextField
                        margin="dense"
                        label="LinkedIn"
                        type="text"
                        fullWidth
                        variant="outlined"
                        name="linkedin"
                        value={formData.socialMedia?.linkedin || ''}
                        onChange={handleChange}
                        sx={{ borderRadius: "5px", "& .MuiOutlinedInput-root": { "& fieldset": { borderColor: "white", borderRadius: "15px" } } }}
                    />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary">Cancel</Button>
                    <Button onClick={handleSubmit} color="primary">Submit</Button>
                </DialogActions>
            </Dialog>
            <Grid >
            <BasicDetail empDetails={empDetails} />
            </Grid>
            
        </Box>
    );
}

export default EmpDetails;
