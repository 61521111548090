import React, { useState, useEffect } from 'react';
import { Drawer, List, ListItem, ListItemText, IconButton, useMediaQuery, useTheme, Grid, Button, Typography } from '@mui/material';
import { Menu, LogoutTwoTone, Dashboard, Layers, CalendarMonthRounded, AirplanemodeActiveTwoTone, Groups2, MessageRounded, FolderCopyRounded, ChatBubble, Login, Leaderboard, CalendarMonth, ReceiptRounded } from '@mui/icons-material';
import { styled } from '@mui/system';
import GlassEffect from '../theme/glassEffect';
import supportIMG from '../assets/images/support.png';
import logoIMG from '../assets/images/logo.png';
import { useAuth } from '../middlewares/auth';
import { Link, useLocation } from 'react-router-dom';
import Diversity2Icon from '@mui/icons-material/Diversity2';
const DrawerStyled = styled(Drawer)(({ theme }) => ({
  '.MuiDrawer-paper': {
    [theme.breakpoints.down('md')]: {
      width: '70%', // Width for mobile and tablet
    },
    [theme.breakpoints.up('md')]: {
      width: '17%', // Width for laptop and larger screens
    },
    height: '100%',
    backgroundColor: 'transparent',
    borderRight: '1px solid rgba(255, 255, 255, 0.2)',
  },
  '.MuiDrawer-docked ': {
    width: '17% !important',
  },
}));

const ListItemStyled = styled(ListItem)(({ theme, active, disabled }) => ({
  backgroundColor: active ? 'rgba(295, 295, 295, 0.9)' : 'transparent',
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: disabled ? 'transparent' : 'rgba(255, 255, 255, 0.2)',
  },
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: active ? '4px' : '0',
    backgroundColor: '#155ed4',
    transition: 'width 0.3s',
  },
  pointerEvents: disabled ? 'none' : 'auto', // Disable pointer events if disabled
  opacity: disabled ? 0.5 : 1, // Change opacity if disabled
}));

const IconContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  '& svg': {
    fontSize: '1.5rem', // Base size
    [theme.breakpoints.down('md')]: {
      fontSize: '1.2rem', // Adjusted size for smaller screens
    },
  },
}));

const Navbar = () => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { user , logout} = useAuth();
  const location = useLocation();
  
  // Map paths to index
  const pathToIndex = {
    '/dashboard': 0,
    '/leads' : 1,
    '/customers': 2,
    '/calendar': 3,
    '/employees': 4,
    '/messages': 5,
    '/marketing':6,
    '/invoices':7,
  };

  useEffect(() => {
    const currentPath = location.pathname;

    let index = null;
    Object.keys(pathToIndex).forEach((path) => {
      if (currentPath === path || currentPath.startsWith(`${path}/`) && !Object.keys(pathToIndex).some(p => currentPath.startsWith(p) && p !== path)) {
        index = pathToIndex[path];
      }
    });
    
    setSelectedIndex(index ?? 0);
  }, [location.pathname]);

  const handleListItemClick = (index) => {
    setSelectedIndex(index);
    if (isMobile) setMobileOpen(false); // Close drawer on mobile after item click
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div style={{ padding: '20px', height: '100vh', background: 'transparent', zIndex: 2, position: 'sticky', top: 0 }}>
      <GlassEffect.GlassContainer sx={{ height: '100%', zIndex: 3, position: 'sticky', top: 0 }}>
        <List sx={{ height: '100%' , position: 'sticky', top: 0 }}>
          <Grid height={'10%'} sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            flexWrap: 'wrap'
          }}>
            <img src={logoIMG} alt="Logo" height={'70px'} width={'70px'} style={{ marginLeft: '10px' }} />
            <Typography sx={{ fontSize: '20px', fontWeight: 'bold', color: '#155ed4' }}>{user?.loginId}</Typography>
          </Grid>
          <Grid minHeight={'60%'}>
            <>
              {Object.entries(pathToIndex).map(([path, index]) => {
                const isDisabled = index !== 1 && index !== 4 && index !== 3; // Disable all except 'Leads'
                return (
                  <Link to={isDisabled ? '#' : path} key={path} style={{ textDecoration: 'none' }}>
                    <ListItemStyled
                      button
                      selected={selectedIndex === index}
                      onClick={() => !isDisabled && handleListItemClick(index)}
                      active={selectedIndex === index}
                      disabled={isDisabled}
                    >
                      <IconContainer alignItems="center">
                        {index === 0 && <Dashboard sx={{ color: selectedIndex === index ? '#155ed4' : '#636161',display : user?.role === 'Admin' || user?.role === 'superAdmin' || user?.role === 'Manager' ?  'block ': 'none' }} />}
                        {index === 1 && <Leaderboard sx={{ color: selectedIndex === index ? '#155ed4' : '#636161' }} />}
                        {index === 2 && <Diversity2Icon sx={{ color: selectedIndex === index ? '#155ed4' : '#636161' }} />}
                        {index === 3 && <CalendarMonth sx={{ color: selectedIndex === index ? '#155ed4' : '#636161' }} />}
                        {index === 4 && <Groups2 sx={{ color: selectedIndex === index ? '#155ed4' : '#636161' }} />}
                        {index === 5 && <MessageRounded sx={{ color: selectedIndex === index ? '#155ed4' : '#636161' }} />}
                        {index === 6 && <FolderCopyRounded sx={{ color: selectedIndex === index ? '#155ed4' : '#636161' }} />}
                        {index === 7 && <ReceiptRounded sx={{ color: selectedIndex === index ? '#155ed4' : '#636161' }} />}
                      </IconContainer>
                      <ListItemText 
        primary={index === 0 ? "Dashboard" :
                 index === 1 ? "Leads" :
                 index === 2 ? "Customers" :
                 index === 3 ? "Calendar" :
                 index === 4 && (user?.role === 'Admin' || user?.role === 'superAdmin' || user?.role === 'Manager') ? "Employees" :
                 index === 5 ? "Messages" :
                 index === 6 ? "Marketing" :
                 index === 7 ? "Invoices" :
                 "Info Portal"
        } 
        sx={{
            color: selectedIndex === index ? '#155ed4' : '#636161',
            textAlign: 'start',
        }} 
    ></ListItemText>
    </ListItemStyled>
                  </Link>
                );
              })}
            </>
          </Grid>
          <Grid height={'10%'} width={'100%'} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <img src={supportIMG} alt="Support" height={'150px'} width={'150px'} />
            <Button>
              <ChatBubble /> Support
            </Button>
          </Grid>
          {user && (
            <Grid height={'10%'} width={'100%'} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: '50px' }}>
              <Button variant="outlined" onClick={() => logout()}>
                <LogoutTwoTone /> Logout
              </Button>
            </Grid>
          )}
           {!user && (
            <Grid height={'10%'} width={'100%'} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: '50px' }}>
              <Link to="/login" style={{ textDecoration: 'none' }}><Button variant="outlined">
                <Login /> Login
              </Button></Link>
            </Grid>
          )}
        </List>
      </GlassEffect.GlassContainer>
    </div>
  );

  return (
    <nav>
      {isMobile ? (
        <>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
            <Menu />
          </IconButton>
          <DrawerStyled
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            variant="temporary"
          >
            {drawer}
          </DrawerStyled>
        </>
      ) : (
        <DrawerStyled
          anchor="left"
          open
          variant="permanent"
        >
          {drawer}
        </DrawerStyled>
      )}
    </nav>
  );
};

export default Navbar;
