import {
    Avatar,
    Box,
    Grid,
    IconButton,
    Typography,
    Collapse,
  } from "@mui/material";
  import React, { useEffect, useMemo, useState } from "react";
  import FireplaceIcon from "@mui/icons-material/Fireplace";
  import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
  import SevereColdIcon from "@mui/icons-material/SevereCold";
  import {
    EditOutlined,
    Email,
    ExpandLess,
    ExpandMore,
    Phone,
  } from "@mui/icons-material";
  import SportsScoreIcon from '@mui/icons-material/SportsScore';
  import SwapCallsIcon from '@mui/icons-material/SwapCalls';
  import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
  import BadgeIcon from "@mui/icons-material/Badge";
  import { useGet } from "../../../helpers/axios/useApi";
  import { useAuth } from "../../../middlewares/auth";
  import SourceIcon from '@mui/icons-material/Source';
  import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ObjectList from "./addinationDetails";


  function LeadDeatilsView({ lead = {}, points = 0 , count = 0}) {
    const { user } = useAuth();
    const [openDetails, setOpenDetails] = useState(true);
    const [filteredData, setFilteredData] = useState(lead?.userFields || {});
    const [additionalDetails, setAdditionalDetails] = useState(false);
    useEffect(() => {
      setFilteredData(lead?.userFields);
    },[lead?.userFields])
    const { data: employees } = useGet(
      "employee/all",
      {},
      {},
      { queryKey: "employees" }
    );
  
    const getEmployeeName = (id) => {
      if (!employees?.data?.message || !Array.isArray(employees?.data?.message[0])) {
        return "Unknown";
      }
      if (!Array.isArray(id) || id.length === 0) {
        return "Unknown";
      }
      let employee = employees?.data?.message[0]?.find(
        (employee) => employee._id === id[0]
      );
      return employee ? `${employee.name}` : "Unknown";
    };
  
    const getOwerName = (id) => {
      let employee = employees?.data?.message[0]?.find(
        (employee) => employee._id === `${id}`
      );
      return employee ? `${employee.name}` : user.name ? user.name : user.companyName;
    };


    return (
      <>
      <Box
        width={"100%"}
        sx={{
          border: "1px solid #dcdce0",
          height: "80vh",
          overflowY: "scroll",
          mt: "10px",
          borderRadius: "10px",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          alignItems: "center",
        }}
      >
        <Grid
          sx={{
            width: "100%",
            padding: "10px",
            borderRadius: "10px",
            backgroundColor: "#1c2d45",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          
           <Grid sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
            height: "23vh",
            gap: "1rem",
            position: "relative",
           }}>
 <Typography
            sx={{
              padding: "5px 7px",
              width: "max-content",
              borderRadius: "5px",
              backgroundColor: "#dcdce0",
              display: "flex",
              alignItems: "left",
              position: "absolute",
              top: "10px",
              left: "10px",
              gap: "0.5rem",
              textAlign: "left",
              height: "max-content",
              fontSize: "12px",
            }}
          >
            {lead.type === "WARM" ? (
              <FireplaceIcon color="warning" fontSize="small" />
            ) : lead.type === "HOT" ? (
              <LocalFireDepartmentIcon color="error" fontSize="small" />
            ) : (
              <SevereColdIcon sx={{ fill: "#38afeb", }} fontSize="small" />
            )}
            {lead.type}
          </Typography>
          <Grid sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
            position: "absolute",
            top: "10px",
            right: "10px",
          }}>

          
          
         
           <Typography
            variant="body1"
            sx={{   padding: "5px 7px",
              width: "max-content",
              borderRadius: "5px",
              backgroundColor: "#dcdce0",
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              height: "max-content",
              fontSize: "12px", }}
          ><SportsScoreIcon sx={{ fill: "#0f6df2", }} fontSize="small"/>  {points}</Typography>
           <Typography
            variant="body1"
            sx={{   padding: "5px 7px",
              width: "max-content",
              borderRadius: "5px",
              backgroundColor: "#dcdce0",
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              height: "max-content",
              fontSize: "12px", }}
          ><SwapCallsIcon sx={{ fill: "#0f6df2", }} fontSize="small"/>  {count}</Typography>
         </Grid>
          <Avatar
            src={lead.leadImage || lead.leadName?.slice(0, 1).toUpperCase()}
            sx={{ width: "50px", height: "50px", margin: "10 auto" , marginTop: "55px", }}
          />
          <Typography
            variant="body1"
            sx={{ fontSize: "16px", marginTop: "20px", color: "white", position: "absolute", top: "95px",  }}
          >{`${lead.leadName}`}</Typography>
            <Typography
            variant="body1"
            sx={{ fontSize: "16px", marginTop: "0px", color: "white", position: "absolute", top: "145px",  }}
          >{`(${lead.leadId})`}</Typography>
          
           </Grid>
              
        </Grid>
        <Grid
          sx={{
            width: "100%",
            padding: "15px 10px",
            borderRadius: "10px",
            backgroundColor: "white",
            display: "flex",
            height: "max-content",
            flexDirection: "column",
            alignItems: "center",
            alignContent: "left",
            justifyContent: "flex-start",
          }}
        >
          
          <Typography
            variant="body1"
            sx={{
              fontSize: "14px",
              width: "100%",
              marginTop: "10px",
              color: "black",
              textAlign: "left",
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              cursor: "pointer",
            }}
          >
            Assignee To -{getEmployeeName(lead.assignee)}
          </Typography>
        </Grid>
        <Grid
          sx={{
            width: "100%",
            borderRadius: "10px",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignContent: "left",
            justifyContent: "flex-start",
          }}
        >
          <IconButton
            sx={{
              width: "100%",
              textAlign: "left",
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "transparent",
                border: "none",
                boxShadow: "none",
              },
              "&:active": {
                backgroundColor: "transparent",
                border: "none",
                boxShadow: "none",
              },
              "&:focus": {
                outline: "none",
              },
            }}
            onClick={() => setOpenDetails(!openDetails)}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "14px",
                width: "100%",
                marginTop: "10px",
                color: "black",
                textAlign: "left",
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                justifyContent: "space-between",
              }}
            >
              <span style={{ color: "#4287f5", fontSize: "20px" }}>
                Basic Details
              </span>
              <span>
                {openDetails ? (
                  <ExpandLess sx={{ fill: "#4287f5" }} />
                ) : (
                  <ExpandMore sx={{ fill: "#4287f5" }} />
                )}
              </span>
            </Typography>
          </IconButton>
          <Collapse in={openDetails} sx={{ width: "100%" }}>
            <Grid
              sx={{
                width: "100%",
                mt: "-20px",
                padding: "15px 10px",
                borderRadius: "10px",
                backgroundColor: "white",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                alignContent: "left",
                justifyContent: "flex-start",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "14px",
                  width: "100%",
                  marginTop: "10px",
                  color: "black",
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                  gap: "0.1rem",
                  cursor: "pointer",
                }}
                onClick={() => window.open(`mailto:${lead.email}`)}
              >
                <Email sx={{ fill: "#1c2d45" }} /> - {lead.email}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "14px",
                  width: "100%",
                  marginTop: "10px",
                  color: "black",
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  cursor: "pointer",
                }}
                onClick={() => window.open(`tel:${lead.mobile}`)}
              >
                <Phone sx={{ fill: "#1c2d45" }} /> - {lead.mobile}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "14px",
                  width: "100%",
                  marginTop: "10px",
                  color: "black",
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  cursor: "pointer",
                }}
                onClick={() => window.open(`tel:${lead.mobile}`)}
              >
               <SourceIcon sx={{ fill: "#1c2d45" }} /> - {lead.source}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "14px",
                  width: "100%",
                  marginTop: "10px",
                  color: "black",
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  cursor: "pointer",
                }}
                onClick={() => window.open(`tel:${lead.mobile}`)}
              >
             <AccountTreeIcon sx={{ fill: "#1c2d45" }} /> - {lead.workFlow}
              </Typography>
            </Grid>
          </Collapse>
        </Grid>
        <Grid
          sx={{
            width: "100%",
            mt: "-10px",
            borderRadius: "10px",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignContent: "left",
            justifyContent: "flex-start",
          }}
        >
          <IconButton
            sx={{
              width: "100%",
              textAlign: "left",
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "transparent",
                border: "none",
                boxShadow: "none",
              },
              "&:active": {
                backgroundColor: "transparent",
                border: "none",
                boxShadow: "none",
              },
              "&:focus": {
                outline: "none",
              },
            }}
            onClick={() => setAdditionalDetails(!additionalDetails)}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "14px",
                width: "100%",
                marginTop: "10px",
                color: "black",
                textAlign: "left",
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                justifyContent: "space-between",
              }}
            >
              <span style={{ color: "#4287f5", fontSize: "20px" }}>
              Additional Details
              </span>
              <span>
                {additionalDetails ? (
                  <ExpandLess sx={{ fill: "#4287f5" }} />
                ) : (
                  <ExpandMore sx={{ fill: "#4287f5" }} />
                )}
              </span>
            </Typography>
          </IconButton>
          <Collapse in={additionalDetails} sx={{ width: "100%" }}>
            <Grid>
            <ObjectList data={filteredData} />
            </Grid>
          </Collapse>
        </Grid>

      </Box>
      </>
    );
  }
  
  export default LeadDeatilsView;
  