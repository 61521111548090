import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  IconButton,
  TextField,
  Button,
  Popper,
  Box,
  Grid,
  TablePagination,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  Autocomplete,
  DialogActions,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  List,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Cancel, Upload } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useGet, usePost } from "../../../helpers/axios/useApi";
import Loading from "../../../assets/Loading/Index";
import { toast } from "react-toastify";
import { setLeadData } from "../../../redux/actions/leads/filter";
import CustomTooltip from "../customTooltip";
import { Link } from "react-router-dom";
import SendTimeExtensionIcon from "@mui/icons-material/SendTimeExtension";
import TransformIcon from "@mui/icons-material/Transform";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import Preloader from "../../../preLoader";
import RatingStars from "./rating";
import { isArray } from "lodash";
const generateIdFromLabel = (label) => {
  const cleanedLabel = label.trim().replace(/\s+/g, " ");
  if (cleanedLabel.includes(" ")) {
    return cleanedLabel
      .split(" ")
      .map((word, index) =>
        index === 0
          ? word.toLowerCase()
          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join("");
  }
  return cleanedLabel.toLowerCase();
};

const LeadTableView = ({ fetched }) => {
  const [filters, setFilters] = useState({});
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortColumn, setSortColumn] = useState(null);
  const [activeFilterColumn, setActiveFilterColumn] = useState(null);
  const [filterValue, setFilterValue] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [columns, setColumns] = useState([]);
  const [reset, setReset] = useState(false);
  const [leadLoading, setLeadLoading] = useState(false);
  const [filterApplied, setFilterApplied] = useState({});
  const [assigningMembers, setAssigningMembers] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [members, setMembers] = useState([]);
  const [rating, setRating] = useState(0);
  const [filterCondition, setFilterCondition] = useState({});
  const [newStage, setNewStage] = useState("");
  const { leadData, column, refetch, range } = useSelector(
    (state) => state.leads
  );
  const [selectedRows, setSelectedRows] = useState(new Set());
  const memoizedFilterValue = useMemo(() => filterValue, [filterValue]);
  const filterButtonRefs = useRef({});
  const {
    data: leads,
    error,
    isLoading,
    refetch: refetchLeads,
  } = useGet(
    "/leads/get",
    {
      page: page + 1,
      limit: rowsPerPage,
      ...filters,
      startDate: range?.startDate,
      endDate: range?.endDate,
    },
    {},
    {
      queryKey: [
        "leads",
        {
          page: page + 1,
          limit: rowsPerPage,
          ...filters,
          startDate: range?.startDate,
          endDate: range?.endDate,
        },
      ],
    }
  );
  const { data: employee, isLoading: employeesLoading } = useGet(
    "employee/all",
    {},
    {},
    { queryKey: "employees" }
  );

  const { mutate: updateLead, isLoading: updateLeadLoading } =
    usePost("leads/bulk-update");
  useEffect(() => {
    if (employee?.data?.message[0]) {
      setEmployees(employee.data.message[0]);
    }
  }, [employee]);

  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const { mutate: addLead } = usePost("/leads/create-bulk");

  useEffect(() => {
    if (leadData.length > 0) {
      setData(leadData);
    } else {
      const leadsData = leads?.data?.data?.leadsData || [];
      setData(leadsData);
      dispatch({
        type: "SET_DOWNLOAD_DATA",
        payload: leadsData,
      });
      setReset(false);
      fetched(leadsData);
    }
  }, [leads, leadData, page, rowsPerPage, reset]);

  useEffect(() => {
    refetchLeads();
    dispatch({ type: "REFETCH_LEADS", payload: false });
  }, [refetch]);

  useEffect(() => {
    const newColumns =
      column
        ?.filter((col) => col.visible)
        .map((col) => ({
          ...col,
          id: generateIdFromLabel(col.label),
          filterable: true,
        })) || [];
    setColumns(newColumns);
  }, [column]);

  const applyFilter = () => {
    if (!activeFilterColumn) return; // Ensure there's an active filter column
    console.log(filterValue);
    
    let updatedFilters = {
      ...filters,
      contain: filterCondition[activeFilterColumn] !== false, // Set the contain condition
    };
  
    // Get the current filter value for the active column
    const currentFilterValue = filterValue[activeFilterColumn] || []; // Default to an empty array if not defined
  
    // Check if currentFilterValue is an array
    if (Array.isArray(currentFilterValue)) {
      // If it's an array, set it directly in updatedFilters
      updatedFilters[activeFilterColumn] = currentFilterValue; // Send as an object key: [values]
    } else {
      // Normalize for case-insensitivity
      const normalizedFilterValue = currentFilterValue.toLowerCase(); // This line might be unnecessary for arrays
      updatedFilters[activeFilterColumn] = normalizedFilterValue; // Set the filter value for other columns
    }
  
    setFilters(updatedFilters);
    setFilterApplied((prev) => ({
      ...prev,
      [activeFilterColumn]: currentFilterValue, // Keep the current filter value
    }));
  
    refetchLeads();
    setActiveFilterColumn(null);
  };
  
  

  const resetFilter = () => {
    if (!activeFilterColumn) return; // Ensure there's an active filter column

    // Create a new filters object without the active filter column
    const { [activeFilterColumn]: _, ...remainingFilters } = filters;

    // Reset the filters state for the active column
    setFilters(remainingFilters);

    // Reset the applied filter for the active column
    setFilterApplied((prev) => {
      const { [activeFilterColumn]: _, ...remaining } = prev;
      return remaining;
    });

    // Reset the specific filter value for the active column
    setFilterValue((prev) => ({
      ...prev,
      [activeFilterColumn]: "", // Reset only the active filter column value
    }));

    // Reset the condition for the active column
    setFilterCondition((prev) => ({
      ...prev,
      [activeFilterColumn]: undefined, // Remove condition for the active column
    }));

    refetchLeads(); // Refetch leads with the updated filters

    setActiveFilterColumn(null); // Reset the active filter column
    setReset(true); // Indicate that a reset has occurred
  };

  const handleSort = (id) => {
    const newDirection =
      sortColumn === id && sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newDirection);
    setSortColumn(id);
  };
  const getEmployeeName = (id) => {
    console.log(id, "id");

    if (Array.isArray(id) && id.length > 0) {
      // If id is an array, map over it to get employee names
      return id
        .map((employeeId) => {
          const employee = employees.find((emp) => emp._id === employeeId);
          return employee ? employee.name : "Unknown"; // Return name or "Unknown"
        })
        .join(", "); // Join names with a comma
    } else {
      // If id is a single value, find the employee
      const employee = employees.find((emp) => emp._id === id);
      return employee ? employee.name : "Unknown"; // Return name or "Unknown"
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedData = useMemo(() => {
    if (!data || !sortColumn) return data;

    return [...data].sort((a, b) => {
      if (a[sortColumn] < b[sortColumn])
        return sortDirection === "asc" ? -1 : 1;
      if (a[sortColumn] > b[sortColumn])
        return sortDirection === "asc" ? 1 : -1;
      return 0;
    });
  }, [data, sortColumn, sortDirection]);

  // Log sorted data
  console.log("Sorted Data: ", sortedData);

  const paginatedData = useMemo(() => {
    if (!sortedData || sortedData.length === 0) return [];

    const start = 0;
    const end = start + rowsPerPage;

    // Ensure indices are within bounds
    const actualEnd = Math.min(end, sortedData.length);

    return sortedData.slice(start, actualEnd);
  }, [sortedData, page, rowsPerPage]);
  console.log("page", paginatedData);

  const totalItemCount =
    leads?.data?.data?.pagination?.totalPages * rowsPerPage;
  const generateId = (label) => {
    if (!label) return "";
    const cleanedLabel = label.trim().replace(/\s+/g, " ");
    return cleanedLabel
      .split(" ")
      .map((word, index) =>
        index === 0
          ? word.toLowerCase()
          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join("");
  };
  const activeColumnType =
    columns?.find((col) => generateId(col.label) === activeFilterColumn)
      ?.type || "text";

  const handleFilterChange = (event) => {
    const { value } = event.target;

    setFilterValue((prev) => ({
      ...prev,
      [activeFilterColumn]: value, // Update only for the active filter column
    }));
    console.log(activeFilterColumn, value);
  };

  const handleUpload = async () => {
    setLeadLoading(true);
    const CHUNK_SIZE = 50;
    const leadDataArray = leadData;

    const chunkArray = (array, size) => {
      const result = [];
      for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
      }
      return result;
    };

    const processedLeads = leadDataArray.map((data) => ({
      leadName: data.leadName,
      mobile: data.mobile,
      source: data.source,
      email: data.email,
      workFlow: data.workFlow,
      type: data.type,
      userFields: Object.keys(data).reduce((fields, key) => {
        if (
          ![
            "leadName",
            "mobile",
            "source",
            "email",
            "workFlow",
            "type",
          ].includes(key)
        ) {
          fields[key] = data[key];
        }
        return fields;
      }, {}),
    }));

    const leadChunks = chunkArray(processedLeads, CHUNK_SIZE);

    const processChunksRecursively = async (chunks) => {
      if (chunks.length === 0) {
        return true;
      }

      const [currentChunk, ...remainingChunks] = chunks;

      try {
        await addLead({ leadsData: currentChunk });
        return await processChunksRecursively(remainingChunks);
      } catch (error) {
        console.error("Submission failed:", error);
        toast.error("Submission failed");
        setLeadLoading(false);
        return false;
      }
    };

    try {
      await processChunksRecursively(leadChunks);
      toast.success("Lead uploaded successfully");
      setLeadLoading(false);
    } catch (error) {
      console.error("Unexpected error:", error);
      toast.error("Submission failed");
    } finally {
      setLeadLoading(false);
      refetchLeads();
      dispatch(setLeadData([]));
    }
  };

  const handleDiscard = () => {
    dispatch(setLeadData([]));
  };
  const handleRowSelect = (rowId) => {
    setSelectedRows((prevSelectedRows) => {
      const newSelectedRows = new Set(prevSelectedRows);
      if (newSelectedRows.has(rowId)) {
        newSelectedRows.delete(rowId);
      } else {
        newSelectedRows.add(rowId);
      }
      return newSelectedRows;
    });
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedRows(new Set(paginatedData.map((row) => row._id)));
    } else {
      setSelectedRows(new Set());
    }
  };
  const handleBulkDelete = async () => {
    setAssigningMembers(true);
    setMembers([]);
  };

  const handCloseAssigningMember = () => {
    setAssigningMembers(false);
    setSelectedMember(null);
    setMembers([]);
  };

  const handleAssignee = () => {
    const leadIds = Array.from(selectedRows);

    if (leadIds.length > 0) {
      const leadsData = leadIds.map((id) => ({
        _id: id,
        assignee: selectedMember,
        newStage,
      }));

      const data = updateLead({ leadsData });
      setAssigningMembers(false);
      toast.success("Assigned successfully");
      setMembers([]);
      setSelectedMember(null);
      refetchLeads();
      setSelectedRows(new Set());
    }
  };

  const { mutate: deleteLeads, isLoading: deleteLoading } =
    usePost("/leads/delete");

  const handleDelete = async () => {
    const leadIds = Array.from(selectedRows);

    if (leadIds.length > 0) {
      const leadsData = leadIds.map((id) => ({ _id: id }));

      const data = deleteLeads({ leadsData });
      await refetchLeads();
      console.log(leadsData, "delete allllll");
      setAssigningMembers(false);
      toast.success("Deleted successfully");
      setSelectedMember(null);
      setMembers([]);
      refetchLeads();
      setSelectedRows(new Set());
    }
  };
  const handleColumnClick = (columnId) => {
    setActiveFilterColumn(columnId);

    const newFilterValue =
      columnId === "Assignee" || columnId === "Owner"
        ? getEmployeeName(filterValue[columnId]) || "" // Call the function if it's Assignee or Owner
        : filterValue[columnId] || ""; // Otherwise, use the existing filter value or an empty string

    setFilterValue((prev) => ({
      ...prev,
      [columnId]: newFilterValue, // Set the new value for the active column
    }));
  };
  const { mutate: rateLead, isLoading: leadRating } = usePost("/leads/rate");
  const handleRatingChange = async (id, rate) => {
    await rateLead({ id, rate });
    refetchLeads();
  };
  const getfilterType = (
    activeColumnType,
    activeFilterColumn,
    filterValue,
    handleFilterChange
  ) => {
    switch (activeColumnType) {
      case "text":
      case "number":
        return (
          <TextField
            size="small"
            value={filterValue[activeFilterColumn] || ""}
            onChange={handleFilterChange}
            type={activeColumnType}
            placeholder="Filter"
            fullWidth
          />
        );

      case "date":
        return (
          <TextField
            size="small"
            value={filterValue[activeFilterColumn] || ""}
            onChange={handleFilterChange}
            type="date"
            placeholder="Filter"
            fullWidth
          />
        );

      case "select":
        return (
          <FormControl fullWidth>
            <InputLabel id={`select-label-${activeFilterColumn}`}>
              Filter
            </InputLabel>
            <Select
  labelId={`select-label-${activeFilterColumn}`}
  value={filterValue[activeFilterColumn] || []}
  onChange={handleFilterChange}
  size="small"
  multiple={activeFilterColumn === "assignee" || activeFilterColumn === "owner"}
  renderValue={(selected) => {
    const selectedNames = selected.map(id => getEmployeeName(id));
    return (
      <Grid display={'flex'} flexDirection={'column'} gap={'0.5rem'}>
        {selectedNames.map((name, index) => (
          <Grid key={index} display="flex" alignItems="center">
            <Checkbox checked />
            <span>{name}</span>
          </Grid>
        ))}
      </Grid>
    );
  }}
  sx={{
    "& .MuiOutlinedInput-root": {
      borderRadius: "15px",
      "& fieldset": {
        borderColor: "#ced1d6",
      },
      "&:hover fieldset": {
        borderColor: "#398bf7",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#398bf7",
      },
    },
  }}
>
  {activeFilterColumn === "assignee" || activeFilterColumn === "owner" ? (
    employees?.map((employee) => (
      <MenuItem key={employee._id} value={employee._id}>
        <Checkbox checked={filterValue[activeFilterColumn]?.includes(employee._id)} />
        {employee.name}
      </MenuItem>
    ))
  ) : (
    <>
      <MenuItem value="true">True</MenuItem>
      <MenuItem value="false">False</MenuItem>
      <MenuItem value="">All</MenuItem>
    </>
  )}
</Select>

          </FormControl>
        );

      default:
        return null; // or a default input type
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      {/* {isLoading && <Preloader />} */}
      {leadLoading === true && <Preloader />}
      {deleteLoading === true && <Preloader />}
      {leadRating === true && <Preloader />}
      <Paper
        sx={{
          width: "100%",
          borderRadius: "10px",
          height: "67vh",
          overflow: "hidden",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
        }}
      >
        <TableContainer
          sx={{
            maxHeight: "100%",
            width: "100%",
            "&::-webkit-scrollbar": {
              width: "8px",
              height: "2px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#4287f5",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "white",
              borderRadius: "4px",
            },
          }}
        >
          <Table sx={{ width: "100%" }}>
            <TableHead sx={{ backgroundColor: "#edeef2" }}>
              <TableRow>
                <TableCell />
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={
                      data.length > 0 &&
                      data.every((row) => selectedRows.has(row._id))
                    }
                    onChange={handleSelectAll}
                    indeterminate={
                      selectedRows.size > 0 && selectedRows.size < data.length
                    }
                  />
                </TableCell>
                {columns?.map((column) => (
                  <TableCell
                    key={column.id}
                    sx={{
                      height: 50,
                      whiteSpace: "nowrap",
                      width: 150,
                      position: "relative",
                      padding: "8px 20px",
                      borderRight: "none",
                      "&::after": {
                        content: '""',
                        position: "absolute",
                        right: 0,
                        top: "50%",
                        transform: "translateY(-50%)",
                        width: "1px",
                        height: "20px",
                        backgroundColor: "#ced1db",
                      },
                    }}
                  >
                    <TableSortLabel
                      active={sortColumn === column.id}
                      direction={
                        sortColumn === column.id ? sortDirection : "asc"
                      }
                      onClick={() => handleSort(column.id)}
                    >
                      {column.label}
                      {sortColumn === column.id ? (
                        sortDirection === "asc" ? (
                          <ArrowDropUpIcon sx={{ ml: 1 }} />
                        ) : (
                          <ArrowDropDownIcon sx={{ ml: 1 }} />
                        )
                      ) : null}
                    </TableSortLabel>
                    <Grid
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <IconButton
                        size="small"
                        edge="end"
                        aria-label="filter"
                        onClick={() => handleColumnClick(column.id)}
                        ref={(el) => (filterButtonRefs.current[column.id] = el)}
                      >
                        <FilterAltIcon
                          color={
                            filterApplied[column.id] ||
                            activeFilterColumn === column.id
                              ? "primary"
                              : "action"
                          }
                          fontSize="small"
                        />
                      </IconButton>

                      {activeFilterColumn === column.id && (
                        <>
                          <Popper
                            open={Boolean(activeFilterColumn === column.id)}
                            anchorEl={filterButtonRefs.current[column.id]}
                            placement="bottom-start"
                            sx={{
                              zIndex: 1,
                              position: "absolute",
                              marginTop: 1,
                              borderRadius: "10px",
                              overflow: "hidden",
                              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                              bgcolor: "white",
                              minWidth: "200px",
                            }}
                          >
                            <Box
                              p={1}
                              bgcolor="white"
                              border={1}
                              borderColor="divider"
                              borderRadius={1}
                              display="flex"
                              flexDirection="column"
                              gap={1}
                            >
                              {columns.find(
                                (col) => col.id === activeFilterColumn
                              )?.filterable ? (
                                <>
                                 <Grid width={"300px"}>
                                 {getfilterType(
                                    activeColumnType,
                                    activeFilterColumn,
                                    filterValue,
                                    handleFilterChange
                                  )}
                                 </Grid>
                                  
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={
                                          filterCondition[
                                            activeFilterColumn
                                          ] !== false
                                        }
                                        onChange={(event) => {
                                          setFilterCondition((prev) => ({
                                            ...prev,
                                            [activeFilterColumn]:
                                              event.target.checked,
                                          }));
                                        }}
                                      />
                                    }
                                    label={
                                      filterCondition[activeFilterColumn] !==
                                      false
                                        ? "Contains"
                                        : "Does Not Contain"
                                    }
                                  />
                                  <Button
                                    onClick={applyFilter}
                                    variant="contained"
                                    color="primary"
                                    sx={{ mt: 1 }}
                                  >
                                    Apply
                                  </Button>
                                  <Button
                                    onClick={resetFilter}
                                    variant="outlined"
                                    color="error"
                                    sx={{ mt: 1 }}
                                  >
                                    Reset
                                  </Button>
                                </>
                              ) : (
                                <Box p={1}>No filterable data available.</Box>
                              )}
                            </Box>
                          </Popper>
                        </>
                      )}
                    </Grid>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  selected={selectedRows.has(row._id)} // Optional: Highlight selected rows
                >
                  <TableCell>
                    <RatingStars
                      rating={row.rating} // Use the initialized rating
                      onRate={(rate) => handleRatingChange(row._id, rate)}
                    />
                  </TableCell>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedRows.has(row._id)}
                      onChange={() => handleRowSelect(row._id)}
                    />
                  </TableCell>
                  {columns?.map((column) => (
                    <CustomTooltip
                      title={
                        column.id !== "assignee" &&
                        column.id !== "owner" &&
                        column.id !== "reportingManager"
                          ? row[column.id]
                          : ""
                      }
                      arrow
                      key={column.id}
                    >
                      <TableCell
                        sx={{
                          padding: "7px 12px",
                          height: "30px",
                          maxWidth: "100px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {column.id === "leadId" ? (
                          <Link to={`/leads/${row._id}`}>
                            {row[column.id]}
                            {row.stage !== "Create" ? (
                              <OnlinePredictionIcon color="success" />
                            ) : null}
                          </Link>
                        ) : column.id === "assignee" ? (
                          Array.isArray(row[column.id]) &&
                          row[column.id].length > 0 ? (
                            <span>{getEmployeeName(row[column.id][0])}</span>
                          ) : (
                            <span>No Assignee</span>
                          )
                        ) : column.id === "owner" ? (
                          row[column.id] && row[column.id][0] ? (
                            <span>
                              {getEmployeeName(row[column.id][0]) === "Unknown"
                                ? "Imperial Milestones"
                                : getEmployeeName(row[column.id][0])}
                            </span>
                          ) : (
                            <span>No Owner</span>
                          )
                        ) : column.id === "reportingManager" ? (
                          row[column.id] ? (
                            <span>
                              {getEmployeeName(row[column.id]) === "Unknown"
                                ? "Imperial Milestones"
                                : getEmployeeName(row[column.id])}
                            </span>
                          ) : (
                            <span>No Reporting Manager</span>
                          )
                        ) : (
                          // Check if the value is a string and starts with "http"
                          <span>
                            {typeof row[column.id] === "string" &&
                            row[column.id].startsWith("http") ? (
                              <a
                                href={row[column.id]}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {row[column.id]}
                              </a>
                            ) : (
                              row[column.id] || "N/A"
                            )}
                          </span>
                        )}
                      </TableCell>
                    </CustomTooltip>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          {leadData.length > 0 && (
            <>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Upload />}
                onClick={handleUpload}
                sx={{
                  textTransform: "none",
                  fontSize: "12px",
                  backgroundColor: "#155ed4",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#155ed4",
                  },
                }}
              >
                Upload Leads
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Cancel />}
                onClick={handleDiscard}
                sx={{
                  textTransform: "none",
                  fontSize: "12px",
                  backgroundColor: "#fa3257",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#f54969",
                  },
                  m: 1,
                }}
              >
                Cancel
              </Button>
            </>
          )}
        </Grid>
        <Grid item>
          {selectedRows.size > 0 && (
            <>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleBulkDelete}
                sx={{
                  textTransform: "none",
                  fontSize: "12px",
                  backgroundColor: "#488ae6",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#488ae6",
                  },
                  m: 1,
                }}
              >
                <SendTimeExtensionIcon />
                Transfer Leads
              </Button>

              <Button
                variant="contained"
                color="secondary"
                onClick={handleDelete}
                sx={{
                  textTransform: "none",
                  fontSize: "12px",
                  backgroundColor: "#e32b44",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#de3c51",
                  },
                  m: 1,
                }}
              >
                <SendTimeExtensionIcon />
                Delete Leads
              </Button>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "gray",
                  m: 1,
                  padding: "5px 20px ",
                  borderRadius: "5px",
                  border: "1px solid gray",
                  backgroundColor: "white",
                }}
              >
                Selected rows: {selectedRows.size}
              </Typography>
            </>
          )}
        </Grid>
        <Grid item xs>
          <TablePagination
            rowsPerPageOptions={[25, 50, 70, 100, 200, 500, 1000]}
            component="div"
            count={totalItemCount || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
      <Dialog
        open={assigningMembers}
        onClose={handCloseAssigningMember}
        PaperProps={{
          sx: {
            borderRadius: "25px",
            padding: "1rem",
          },
        }}
      >
        {/* {isAddingNewMember && <Loading />} */}
        <DialogTitle>Assginee</DialogTitle>
        {/* <Avatar
          src={teamImage}
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: "0",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            mixBlendMode: "normal",
            "& img": {
              filter: "blur(10px)",
              width: "100%",
              height: "100%",
              objectFit: "cover",
            },
          }}
        /> */}
        <DialogContent>
          <Box
            sx={{
              width: "500px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              mt: 2,
            }}
          >
            <Autocomplete
              multiple
              options={employees?.map((employee) => ({
                label: `${employee.name} (${employee.position})`,
                _id: employee._id,
              }))}
              getOptionLabel={(option) => option.label || ""}
              value={members}
              onChange={(_, newValue) => {
                setMembers(newValue);
                setSelectedMember(newValue?.map((item) => item._id));
              }}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Members"
                  variant="outlined"
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "#000",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#ced1d6",
                        borderRadius: "15px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        border: "none",
                      },
                      "&:hover fieldset": {
                        borderColor: "#398bf7",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#398bf7",
                      },
                    },
                  }}
                />
              )}
            />
            <Autocomplete
              options={[
                "Create",
                "Follow Up Required",
                "Booking",
                "Session",
                "Payment",
                "Success",
                "DNP - Did Not Pick up",
                "Not Interested",
                "Invalid Number",
              ]}
              value={newStage || ""}
              onChange={(_, newValue) => {
                setNewStage(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Stage"
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "15px",
                      "& fieldset": {
                        borderColor: "#ced1d6",
                      },
                      "&:hover fieldset": {
                        borderColor: "#398bf7",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#398bf7",
                      },
                    },
                  }}
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            onClick={handCloseAssigningMember}
            sx={{
              borderRadius: "15px",
              padding: "8px 16px",
              display: "flex",
              alignItems: "center",
              "&:hover": {
                backgroundColor: "#d32f2f",
              },
            }}
          >
            <Cancel sx={{ color: "white", fontSize: "20px" }} />
            <Typography sx={{ ml: 1, color: "white", fontWeight: "500" }}>
              Cancel
            </Typography>
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAssignee}
            sx={{
              borderRadius: "15px",
              padding: "8px 16px",
              display: "flex",
              alignItems: "center",
              "&:hover": {
                backgroundColor: "#357ae8",
              },
            }}
          >
            <TransformIcon sx={{ color: "white", fontSize: "20px" }} />
            <Typography sx={{ ml: 1, color: "white", fontWeight: "500" }}>
              Assignee
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default LeadTableView;
